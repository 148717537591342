import styled from '@emotion/styled';
import MuiMenu from '@mui/material/Menu';
import React, { ComponentProps, useEffect, useRef } from 'react';
const Container = styled(MuiMenu)``;
type Props = ComponentProps<typeof MuiMenu>;

export const Menu: React.FC<Props> = ({
	anchorEl,
	open,
	onClose,
	...props
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const getContentDiv = () => ref.current?.querySelector('.MuiMenu-paper');
	useEffect(() => {
		if (!open) return;

		const onMouseleave = (e: Event) => {
			onClose?.(e, 'escapeKeyDown');
		};

		const listenerRemover = () => {
			const contentDiv = getContentDiv();
			if (contentDiv == null) return;
			contentDiv.removeEventListener('mouseleave', onMouseleave);
		};
		/**
		 * make sure all listeners were removed.
		 * since i used setTimeout.
		 */
		listenerRemover();
		setTimeout(() => {
			const contentDiv = getContentDiv();
			if (contentDiv == null) return;

			contentDiv.addEventListener('mouseleave', onMouseleave);
		}, 0);
		return listenerRemover;
	}, [open, onClose]);
	return (
		<Container
			{...props}
			ref={ref}
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2))',
					mt: 3.5,
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 20,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translate(-50%, -50%) rotate(45deg)',
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		></Container>
	);
};
