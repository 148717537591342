import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import DashboardSidebar from './DashboardSidebar';

import { useEffect, useState } from 'react';
import useCollapseDrawer from '@/hooks/useCollapseDrawer';
import DashboardNavbar from './DashboardNavbar';
import { Box } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden',
}));

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingBottom: theme.spacing(10),
	backgroundColor: '#fff',
	[theme.breakpoints.up('lg')]: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
	const theme = useTheme();
	const { collapseClick } = useCollapseDrawer();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		localStorage.removeItem('refreshCount');
	}, []);

	return (
		<>
			<RootStyle className="margin-auto">
				<DashboardSidebar
					isOpenSidebar={open}
					onCloseSidebar={() => setOpen(false)}
				/>
				<MainStyle
					sx={{
						transition: theme.transitions.create('margin', {
							duration: theme.transitions.duration.complex,
						}),
						...(collapseClick && {
							ml: '102px',
						}),
					}}
				>
					<DashboardNavbar onOpenSidebar={() => setOpen(true)} />
					<Box sx={{ mt: '100px' }}>
						<Outlet />
					</Box>
				</MainStyle>
			</RootStyle>
		</>
	);
}
