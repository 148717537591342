import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import snackBarOption from './utils/snackBarOption';
import useAuth from './hooks/useAuth';
import { isString } from 'lodash';

export let queryClientRef: React.MutableRefObject<QueryClient> | null = null;
const Provider: React.FC<{
	children: React.ReactElement;
}> = ({ children }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { logout } = useAuth();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onError = (error: any) => {
		const message = error?.data.message;
		if (error.status === 401 && message === 'Invalid token.') {
			return logout();
		}
		if (isString(message)) {
			enqueueSnackbar(message, snackBarOption('error', closeSnackbar));
		}
	};

	const mutationCacheRef = useRef<MutationCache>(
		new MutationCache({
			onError,
		})
	);

	const queryCacheRef = useRef<QueryCache>(
		new QueryCache({
			onError,
		})
	);

	queryClientRef = useRef(
		new QueryClient({
			mutationCache: mutationCacheRef.current,
			queryCache: queryCacheRef.current,
			defaultOptions: {
				queries: {
					refetchOnWindowFocus: false, // default: true
					staleTime: 30 * 1e3,
					retry: false,
				},
			},
		})
	);

	if (queryClientRef.current == null) return null;

	return (
		<QueryClientProvider client={queryClientRef.current}>
			{children}
		</QueryClientProvider>
	);
};

export default Provider;
