import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import { useShopInfoValue } from '@/pages/onboarding-process/model';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backgroundColor: theme.palette.background.paper,
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
	},
	'.username': {
		color: '#000',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '20px',
		marginRight: '4px',
	},
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	minHeight: APPBAR_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: APPBAR_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
	onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
	onOpenSidebar,
}: DashboardNavbarProps) {
	const { isCollapse } = useCollapseDrawer();
	const shopInfo = useShopInfoValue();

	return (
		<RootStyle
			sx={{
				...(isCollapse && {
					width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
				}),
			}}
		>
			<ToolbarStyle>
				<MHidden width="lgUp">
					<IconButton
						onClick={onOpenSidebar}
						sx={{ mr: 1, color: 'text.primary' }}
					>
						<Icon icon={menu2Fill} />
					</IconButton>
				</MHidden>

				<Box sx={{ flexGrow: 1 }} />

				<Stack
					direction="row"
					alignItems="center"
					spacing={{ xs: 0.5, sm: 1.5 }}
				>
					<MHidden width="lgDown">
						<span className="username">{shopInfo?.name}</span>
					</MHidden>
					<AccountPopover />
				</Stack>
			</ToolbarStyle>
		</RootStyle>
	);
}
