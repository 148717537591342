import { useState } from 'react';

export const useFuncLoading = (
	func?: React.EventHandler<React.FormEvent | React.MouseEvent>
) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const _onClick: typeof func = async (e) => {
		e.stopPropagation();

		if (isLoading) return;
		/**
		 * 如果onClick不是异步的，这个setTimeout可以减少一次render
		 */
		const id = setTimeout(() => setIsLoading(true), 0);
		try {
			await func?.(e);
		} catch (e) {
			console.error(e);
		}
		clearTimeout(id);
		setIsLoading(false);
	};
	return [_onClick, isLoading] as const;
};
