import { useMediaQuery } from '@mui/material';

export const breakpointsByDevice = {
	/**
	 * phone
	 */
	phone: 'sm',
	/**
	 * pad
	 */
	pad: 'md',
	/**
	 * laptop
	 */
	laptop: 'lg',
	/**
	 * Desktop
	 */
	desktop: 'xl',
} as const;

export const breakpoints = {
	xs: 0,
	/**
	 * phone
	 */
	[breakpointsByDevice.phone]: 375,
	/**
	 * pad
	 */
	[breakpointsByDevice.pad]: 768,
	/**
	 * laptop
	 */
	[breakpointsByDevice.laptop]: 1200,
	/**
	 * Desktop
	 */
	[breakpointsByDevice.desktop]: 1540,
} as const;

export enum DeviceType {
	phone = 'phone',
	pad = 'pad',
	laptop = 'laptop',
	desktop = 'desktop',
}

export const onMobile = () =>
	`@media screen and (min-width: 0) and (max-width: ${breakpoints[breakpointsByDevice.laptop] - 1
	}px)`;
export const onPhone = () =>
	`@media screen and (min-width: 0) and (max-width: ${breakpoints[breakpointsByDevice.pad] - 1
	}px)`;

export const onPad = (only = false) =>
	`@media screen and (min-width: ${breakpoints[breakpointsByDevice.pad]}px) ${only
		? `and (max-width: ${breakpoints[breakpointsByDevice.laptop] - 1
		}px)`
		: ''
	}`;
export const onLaptop = (only = false) =>
	`@media screen and (min-width: ${breakpoints[breakpointsByDevice.laptop]
	}px) ${only
		? `and (max-width: ${breakpoints[breakpointsByDevice.desktop] - 1
		}px)`
		: ''
	}`;

export const onDesktop = () =>
	`@media screen and (min-width: ${breakpoints[breakpointsByDevice.desktop]
	}px)`;
export const useIsOnMobile = () => useMediaQuery(onMobile());
export const useIsOnPhone = () => useMediaQuery(onPhone());
export const useIsOnPad = () => useMediaQuery(onPad(true));
export const useIsOnLaptop = () => useMediaQuery(onLaptop(true));
export const useIsOnDesktop = () => useMediaQuery(onDesktop());

const DeviceType2Breakpoints = {
	[DeviceType.phone]: breakpointsByDevice.phone,
	[DeviceType.pad]: breakpointsByDevice.pad,
	[DeviceType.laptop]: breakpointsByDevice.laptop,
	[DeviceType.desktop]: breakpointsByDevice.desktop,
};
export const onBetween = (startType: DeviceType, endType: DeviceType) => {
	return `@media screen and (min-width: ${breakpoints[DeviceType2Breakpoints[startType]]
		}px) and (max-width: ${breakpoints[DeviceType2Breakpoints[endType]] - 1
		}px)`;
};

const onForword = (type: 'up' | 'down', deviceType: DeviceType) => {
	const map = {
		up: 'min',
		down: 'max',
	};
	return `@media screen and (${map[type]}-width: ${breakpoints[DeviceType2Breakpoints[deviceType]]
		}px)`;
};

export const onBreakpointUp = onForword.bind(null, 'up');
export const onBreakpointDown = onForword.bind(null, 'down');

const useDeviceType = () => {
	const isOnPhone = useIsOnPhone();
	const isOnPad = useIsOnPad();
	const isOnLaptop = useIsOnLaptop();
	const isOnDesktop = useIsOnDesktop();
	if (isOnDesktop) {
		return DeviceType.desktop;
	}
	if (isOnLaptop) {
		return DeviceType.laptop;
	}
	if (isOnPad) {
		return DeviceType.pad;
	}
	if (isOnPhone) {
		return DeviceType.phone;
	}
	throw new Error('never');
};

export const useValuesByDeviceType = <
	T,
	DESKTOP extends T = T,
	LAPTOP extends T = T,
	PAD extends T = T,
	PHONE extends T = T
>(
	desktop: DESKTOP,
	laptop: LAPTOP,
	pad: PAD,
	phone: PHONE
) => {
	const buttonSizeMap = {
		[DeviceType.phone]: phone,
		[DeviceType.pad]: pad,
		[DeviceType.laptop]: laptop,
		[DeviceType.desktop]: desktop,
	};
	return buttonSizeMap[useDeviceType()];
};
