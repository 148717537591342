import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as arrowRightSvg } from '@/assets/images/symbol/arrow-right.svg';
import { shouldForwardProp } from '@/utils/styled-props-filter';

type Prop = {
	color: string;
	size: number;
};

const Container = styled(arrowRightSvg, {
	shouldForwardProp: shouldForwardProp('color', 'size'),
})<Prop>`
	vertical-align: middle;
	height: ${({ size }) => `${size}px`};

	&.up {
		transform: rotateZ(-90deg);
	}
	&.down {
		transform: rotateZ(90deg);
	}
	&.left {
		transform: rotateZ(180deg);
	}
	path {
		stroke: ${({ color }) => color};
	}
`;
export const Arrow: React.FC<{
	className?: string;
	direction?: 'up' | 'down' | 'left' | 'right';
	color?: string;
	/**
	 * the width.
	 */
	size?: number;
}> = ({ size, className, color, direction }) => {
	return (
		<Container
			size={size ?? 14}
			color={color ?? 'var(--main-text-color)'}
			className={classNames(className, direction)}
		></Container>
	);
};
