/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */

export type AddressType = (typeof AddressType)[keyof typeof AddressType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressType = {
	user: 'user',
	shop: 'shop',
} as const;
