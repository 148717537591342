import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function SvgIcon(theme: Theme) {
	return {
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeSmall: {
					width: 12,
					height: 12,
					fontSize: 'inherit',
				},
				fontSizeMedium: {
					width: 14,
					height: 14,
					fontSize: 'inherit',
				},
				fontSizeLarge: {
					width: 32,
					height: 32,
					fontSize: 'inherit',
				},
			},
		},
	};
}
