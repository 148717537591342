import { fromSignedUrl, presignUrl } from '@/api/file/file';
import axios from 'axios';

export async function upload(file: File) {
	const {
		data: { url },
	} = await presignUrl({
		filename: file.name,
		content_type: file.type,
	});
	await axios.put(url, file, {
		headers: { 'Content-Type': file.type },
		transformRequest: (data, headers) => {
			headers.delete('Authorization');
			return data;
		},
	});
	const { data } = await fromSignedUrl({ mime: file.type, url: url });
	return data;
}
