/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from '@tanstack/react-query';
import type {
	Address,
	MallAddressListList200,
	MallAddressListListParams,
	MarkDefaultInput,
	MallMy200,
	MallMyParams,
	MallShop200,
	AddressUpdate,
} from '.././model';
import { axiosInstance } from '../../utils/axios';
import type { ErrorType } from '../../utils/axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
	T,
>() => T extends Y ? 1 : 2
	? A
	: B;

type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<
		{ [Q in P]: T[P] },
		{ -readonly [Q in P]: T[P] },
		P
	>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
	k: infer I,
) => void
	? I
	: never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
	? {
			[P in keyof Writable<T>]: T[P] extends object
				? NonReadonly<NonNullable<T[P]>>
				: T[P];
		}
	: DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
	config: any,
	args: infer P,
) => any
	? P
	: never;

export const mallAddressCreateCreate = (
	address: NonReadonly<Address>,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Address>(
		{
			url: `/mall/address.create/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: address,
		},
		options,
	);
};

export const getMallAddressCreateCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressCreateCreate>>,
		TError,
		{ data: NonReadonly<Address> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallAddressCreateCreate>>,
	TError,
	{ data: NonReadonly<Address> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallAddressCreateCreate>>,
		{ data: NonReadonly<Address> }
	> = (props) => {
		const { data } = props ?? {};

		return mallAddressCreateCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallAddressCreateCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallAddressCreateCreate>>
>;
export type MallAddressCreateCreateMutationBody = NonReadonly<Address>;
export type MallAddressCreateCreateMutationError = ErrorType<unknown>;

export const useMallAddressCreateCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressCreateCreate>>,
		TError,
		{ data: NonReadonly<Address> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallAddressCreateCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallAddressDeleteDelete = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{ url: `/mall/address.delete/${id}/`, method: 'post' },
		options,
	);
};

export const getMallAddressDeleteDeleteMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressDeleteDelete>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallAddressDeleteDelete>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallAddressDeleteDelete>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return mallAddressDeleteDelete(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallAddressDeleteDeleteMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallAddressDeleteDelete>>
>;

export type MallAddressDeleteDeleteMutationError = ErrorType<unknown>;

export const useMallAddressDeleteDelete = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressDeleteDelete>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallAddressDeleteDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Return user's default address.
 */
export const mallGetDefault = (
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Address>(
		{ url: `/mall/address.getDefault/`, method: 'get', signal },
		options,
	);
};

export const getMallGetDefaultQueryKey = () =>
	[`/mall/address.getDefault/`] as const;

export const getMallGetDefaultQueryOptions = <
	TData = Awaited<ReturnType<typeof mallGetDefault>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof mallGetDefault>>,
		TError,
		TData
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseQueryOptions<
	Awaited<ReturnType<typeof mallGetDefault>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallGetDefaultQueryKey();

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallGetDefault>>
	> = ({ signal }) => mallGetDefault(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallGetDefaultQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallGetDefault>>
>;
export type MallGetDefaultQueryError = ErrorType<unknown>;

/**
 * @summary Return user's default address.
 */
export const useMallGetDefault = <
	TData = Awaited<ReturnType<typeof mallGetDefault>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof mallGetDefault>>,
		TError,
		TData
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallGetDefaultQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallAddressListList = (
	params?: MallAddressListListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallAddressListList200>(
		{ url: `/mall/address.list/`, method: 'get', params, signal },
		options,
	);
};

export const getMallAddressListListQueryKey = (
	params?: MallAddressListListParams,
) => [`/mall/address.list/`, ...(params ? [params] : [])] as const;

export const getMallAddressListListQueryOptions = <
	TData = Awaited<ReturnType<typeof mallAddressListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallAddressListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallAddressListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallAddressListList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallAddressListListQueryKey(params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallAddressListList>>
	> = ({ signal }) => mallAddressListList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallAddressListListQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallAddressListList>>
>;
export type MallAddressListListQueryError = ErrorType<unknown>;

export const useMallAddressListList = <
	TData = Awaited<ReturnType<typeof mallAddressListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallAddressListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallAddressListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallAddressListListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Mark address default.
 */
export const mallMarkDefault = (
	markDefaultInput: MarkDefaultInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Address>(
		{
			url: `/mall/address.markDefault/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: markDefaultInput,
		},
		options,
	);
};

export const getMallMarkDefaultMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallMarkDefault>>,
		TError,
		{ data: MarkDefaultInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallMarkDefault>>,
	TError,
	{ data: MarkDefaultInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallMarkDefault>>,
		{ data: MarkDefaultInput }
	> = (props) => {
		const { data } = props ?? {};

		return mallMarkDefault(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallMarkDefaultMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallMarkDefault>>
>;
export type MallMarkDefaultMutationBody = MarkDefaultInput;
export type MallMarkDefaultMutationError = ErrorType<unknown>;

/**
 * @summary Mark address default.
 */
export const useMallMarkDefault = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallMarkDefault>>,
		TError,
		{ data: MarkDefaultInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallMarkDefaultMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Return the address list of buyer
 */
export const mallMy = (
	params?: MallMyParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallMy200>(
		{ url: `/mall/address.my/`, method: 'get', params, signal },
		options,
	);
};

export const getMallMyQueryKey = (params?: MallMyParams) =>
	[`/mall/address.my/`, ...(params ? [params] : [])] as const;

export const getMallMyQueryOptions = <
	TData = Awaited<ReturnType<typeof mallMy>>,
	TError = ErrorType<unknown>,
>(
	params?: MallMyParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallMy>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof mallMy>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallMyQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof mallMy>>> = ({
		signal,
	}) => mallMy(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallMyQueryResult = NonNullable<Awaited<ReturnType<typeof mallMy>>>;
export type MallMyQueryError = ErrorType<unknown>;

/**
 * @summary Return the address list of buyer
 */
export const useMallMy = <
	TData = Awaited<ReturnType<typeof mallMy>>,
	TError = ErrorType<unknown>,
>(
	params?: MallMyParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallMy>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallMyQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Return shop address list which the user is member of
 */
export const mallShop = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallShop200>(
		{ url: `/mall/address.shop/${id}/`, method: 'get', signal },
		options,
	);
};

export const getMallShopQueryKey = (id: string) =>
	[`/mall/address.shop/${id}/`] as const;

export const getMallShopQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShop>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShop>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof mallShop>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallShopQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof mallShop>>> = ({
		signal,
	}) => mallShop(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type MallShopQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShop>>
>;
export type MallShopQueryError = ErrorType<unknown>;

/**
 * @summary Return shop address list which the user is member of
 */
export const useMallShop = <
	TData = Awaited<ReturnType<typeof mallShop>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShop>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallAddressUpdatePartialUpdate = (
	id: string,
	addressUpdate: NonReadonly<AddressUpdate>,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<AddressUpdate>(
		{
			url: `/mall/address.update/${id}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: addressUpdate,
		},
		options,
	);
};

export const getMallAddressUpdatePartialUpdateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressUpdatePartialUpdate>>,
		TError,
		{ id: string; data: NonReadonly<AddressUpdate> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallAddressUpdatePartialUpdate>>,
	TError,
	{ id: string; data: NonReadonly<AddressUpdate> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallAddressUpdatePartialUpdate>>,
		{ id: string; data: NonReadonly<AddressUpdate> }
	> = (props) => {
		const { id, data } = props ?? {};

		return mallAddressUpdatePartialUpdate(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallAddressUpdatePartialUpdateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallAddressUpdatePartialUpdate>>
>;
export type MallAddressUpdatePartialUpdateMutationBody =
	NonReadonly<AddressUpdate>;
export type MallAddressUpdatePartialUpdateMutationError = ErrorType<unknown>;

export const useMallAddressUpdatePartialUpdate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddressUpdatePartialUpdate>>,
		TError,
		{ id: string; data: NonReadonly<AddressUpdate> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallAddressUpdatePartialUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallAddressRead = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Address>(
		{ url: `/mall/address/${id}/`, method: 'get', signal },
		options,
	);
};

export const getMallAddressReadQueryKey = (id: string) =>
	[`/mall/address/${id}/`] as const;

export const getMallAddressReadQueryOptions = <
	TData = Awaited<ReturnType<typeof mallAddressRead>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallAddressRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallAddressRead>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallAddressReadQueryKey(id);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallAddressRead>>
	> = ({ signal }) => mallAddressRead(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type MallAddressReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallAddressRead>>
>;
export type MallAddressReadQueryError = ErrorType<unknown>;

export const useMallAddressRead = <
	TData = Awaited<ReturnType<typeof mallAddressRead>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallAddressRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallAddressReadQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};
