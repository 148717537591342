import styled from '@emotion/styled';
import { Button as MUIButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import React, { ComponentProps, useEffect, useRef, useState } from 'react';

import { useFuncLoading } from '@/hooks/useFuncLoading';

const Container = styled(MUIButton)`
	box-sizing: border-box;
	box-shadow: unset;
	font-weight: 700;
	white-space: pre;

	min-width: unset;
	font-weight: 700;
	text-transform: initial;
	& + & {
		margin-left: 10rem;
	}
	.wonder-button_spin {
		visibility: hidden;
		position: absolute;
		&.loading {
			visibility: initial;
		}
	}
	.wonder-button_label {
		&.loading {
			visibility: hidden;
		}
	}

	&.big {
		padding: 0 24px;
		height: 40px;
		font-size: 16px;
		border-radius: 8px;
	}
	&.medium {
		padding: 0 12px;
		height: 36px;
		font-size: 14px;
		border-radius: 8px;
	}
	&.small {
		height: 24px;
		padding: 0 12px;
		font-size: 12px;
		border-radius: 4px;
	}

	&.tiny {
		height: 18px;
		padding: 0 5.5px;
		font-size: 9px;
		border-radius: 3px;
	}

	&.MuiButton-text {
		padding: 0;
	}

	&.Mui-disabled {
		background: #c4c4c4;
		color: #fff;
	}
`;
type Props = ComponentProps<typeof MUIButton>;
export type WonderButtonSizeType = 'big' | 'medium' | 'small' | 'tiny';
export const Button: React.FC<
	Props & {
		wonderSize?: WonderButtonSizeType;
		loading?: boolean;
	}
> = ({ onClick, className, wonderSize, children, loading, ...props }) => {
	const [formLoading, setFormLoading] = useState(false);
	const [_onClick, isLoading] = useFuncLoading(onClick);
	const innerLoading = isLoading || loading || formLoading;
	const ref = useRef(null);
	useEffect(() => {
		if (ref.current == null) return;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(ref.current as any).__wonderSetFormLoading__ = setFormLoading;
	}, []);
	return (
		<Container
			{...props}
			ref={ref}
			className={classNames(
				wonderSize ?? 'medium',
				className,
				'wonder-button',
				'relative'
			)}
			onClick={(e) => {
				if (innerLoading) return;
				_onClick(e);
			}}
		>
			<CircularProgress
				className={classNames('wonder-button_spin', {
					loading: innerLoading,
				})}
				size="1em"
				color="inherit"
			/>
			<span
				className={classNames('wonder-button_label', {
					loading: innerLoading,
				})}
			>
				{children}
			</span>
		</Container>
	);
};
