/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from '@tanstack/react-query';
import type {
	SellerLoginOutput,
	LoginInputBody,
	SellerOauthShopifyAuthorizeCodeCreateParams,
	ShopifyInfoResponse,
	ShopifyRedirectResponse,
	SellerOauthShopifyRedirectListParams,
	SellerInquiryInput,
} from '.././model';
import { axiosInstance } from '../../utils/axios';
import type { ErrorType } from '../../utils/axios';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
	config: any,
	args: infer P,
) => any
	? P
	: never;

export const mallShopShopifyRegisterWebhookCreate = (
	shopId: string,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{
			url: `/mall/shop/${shopId}/shopify.register_webhook`,
			method: 'post',
		},
		options,
	);
};

export const getMallShopShopifyRegisterWebhookCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopShopifyRegisterWebhookCreate>>,
		TError,
		{ shopId: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopShopifyRegisterWebhookCreate>>,
	TError,
	{ shopId: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopShopifyRegisterWebhookCreate>>,
		{ shopId: string }
	> = (props) => {
		const { shopId } = props ?? {};

		return mallShopShopifyRegisterWebhookCreate(shopId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopShopifyRegisterWebhookCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopShopifyRegisterWebhookCreate>>
>;

export type MallShopShopifyRegisterWebhookCreateMutationError =
	ErrorType<unknown>;

export const useMallShopShopifyRegisterWebhookCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopShopifyRegisterWebhookCreate>>,
		TError,
		{ shopId: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopShopifyRegisterWebhookCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const sellerLoginCreate = (
	loginInputBody: LoginInputBody,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<SellerLoginOutput>(
		{
			url: `/seller/login/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: loginInputBody,
		},
		options,
	);
};

export const getSellerLoginCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerLoginCreate>>,
		TError,
		{ data: LoginInputBody },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof sellerLoginCreate>>,
	TError,
	{ data: LoginInputBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof sellerLoginCreate>>,
		{ data: LoginInputBody }
	> = (props) => {
		const { data } = props ?? {};

		return sellerLoginCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type SellerLoginCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof sellerLoginCreate>>
>;
export type SellerLoginCreateMutationBody = LoginInputBody;
export type SellerLoginCreateMutationError = ErrorType<unknown>;

export const useSellerLoginCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerLoginCreate>>,
		TError,
		{ data: LoginInputBody },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getSellerLoginCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const sellerOauthShopifyAuthorizeCodeCreate = (
	params: SellerOauthShopifyAuthorizeCodeCreateParams,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{ url: `/seller/oauth/shopify.authorize_code`, method: 'post', params },
		options,
	);
};

export const getSellerOauthShopifyAuthorizeCodeCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerOauthShopifyAuthorizeCodeCreate>>,
		TError,
		{ params: SellerOauthShopifyAuthorizeCodeCreateParams },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof sellerOauthShopifyAuthorizeCodeCreate>>,
	TError,
	{ params: SellerOauthShopifyAuthorizeCodeCreateParams },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof sellerOauthShopifyAuthorizeCodeCreate>>,
		{ params: SellerOauthShopifyAuthorizeCodeCreateParams }
	> = (props) => {
		const { params } = props ?? {};

		return sellerOauthShopifyAuthorizeCodeCreate(params, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type SellerOauthShopifyAuthorizeCodeCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof sellerOauthShopifyAuthorizeCodeCreate>>
>;

export type SellerOauthShopifyAuthorizeCodeCreateMutationError =
	ErrorType<unknown>;

export const useSellerOauthShopifyAuthorizeCodeCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerOauthShopifyAuthorizeCodeCreate>>,
		TError,
		{ params: SellerOauthShopifyAuthorizeCodeCreateParams },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getSellerOauthShopifyAuthorizeCodeCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const sellerOauthShopifyInfoList = (
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<ShopifyInfoResponse>(
		{ url: `/seller/oauth/shopify.info`, method: 'get', signal },
		options,
	);
};

export const getSellerOauthShopifyInfoListQueryKey = () =>
	[`/seller/oauth/shopify.info`] as const;

export const getSellerOauthShopifyInfoListQueryOptions = <
	TData = Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>,
		TError,
		TData
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseQueryOptions<
	Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getSellerOauthShopifyInfoListQueryKey();

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>
	> = ({ signal }) => sellerOauthShopifyInfoList(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type SellerOauthShopifyInfoListQueryResult = NonNullable<
	Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>
>;
export type SellerOauthShopifyInfoListQueryError = ErrorType<unknown>;

export const useSellerOauthShopifyInfoList = <
	TData = Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof sellerOauthShopifyInfoList>>,
		TError,
		TData
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getSellerOauthShopifyInfoListQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const sellerOauthShopifyRedirectList = (
	params: SellerOauthShopifyRedirectListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<ShopifyRedirectResponse>(
		{
			url: `/seller/oauth/shopify.redirect`,
			method: 'get',
			params,
			signal,
		},
		options,
	);
};

export const getSellerOauthShopifyRedirectListQueryKey = (
	params: SellerOauthShopifyRedirectListParams,
) => [`/seller/oauth/shopify.redirect`, ...(params ? [params] : [])] as const;

export const getSellerOauthShopifyRedirectListQueryOptions = <
	TData = Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>,
	TError = ErrorType<unknown>,
>(
	params: SellerOauthShopifyRedirectListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ??
		getSellerOauthShopifyRedirectListQueryKey(params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>
	> = ({ signal }) =>
		sellerOauthShopifyRedirectList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type SellerOauthShopifyRedirectListQueryResult = NonNullable<
	Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>
>;
export type SellerOauthShopifyRedirectListQueryError = ErrorType<unknown>;

export const useSellerOauthShopifyRedirectList = <
	TData = Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>,
	TError = ErrorType<unknown>,
>(
	params: SellerOauthShopifyRedirectListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof sellerOauthShopifyRedirectList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getSellerOauthShopifyRedirectListQueryOptions(
		params,
		options,
	);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const sellerSellerInquiryCreate = (
	sellerInquiryInput: SellerInquiryInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{
			url: `/seller/sellerInquiry/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: sellerInquiryInput,
		},
		options,
	);
};

export const getSellerSellerInquiryCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerSellerInquiryCreate>>,
		TError,
		{ data: SellerInquiryInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof sellerSellerInquiryCreate>>,
	TError,
	{ data: SellerInquiryInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof sellerSellerInquiryCreate>>,
		{ data: SellerInquiryInput }
	> = (props) => {
		const { data } = props ?? {};

		return sellerSellerInquiryCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type SellerSellerInquiryCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof sellerSellerInquiryCreate>>
>;
export type SellerSellerInquiryCreateMutationBody = SellerInquiryInput;
export type SellerSellerInquiryCreateMutationError = ErrorType<unknown>;

export const useSellerSellerInquiryCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof sellerSellerInquiryCreate>>,
		TError,
		{ data: SellerInquiryInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getSellerSellerInquiryCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
