import styled from '@emotion/styled';
import { useThrottleFn } from 'ahooks';
import React, { forwardRef, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';

import { shouldForwardProp } from '@/utils/styled-props-filter';

const Container = styled(SimpleBar, {
	shouldForwardProp: shouldForwardProp('safeedge', 'hideHorizontal'),
})<{
	safeedge: number;
	hideHorizontal: boolean;
}>`
	padding-right: var(--scrollbar-padding);
	--safeedge: ${(props) => `${props.safeedge}px`};

	.simplebar-content {
		overflow-x: ${({ hideHorizontal }) =>
			hideHorizontal ? 'hidden' : 'visible'};
	}
	.simplebar-track.simplebar-horizontal {
		overflow-x: ${({ hideHorizontal }) =>
			hideHorizontal ? 'none' : 'initial'};
	}
`;
type Prop = SimpleBar['props'] & {
	children?: React.ReactNode;
} & {
	onHitBottom?: () => void;
	onHitTop?: () => void;
	disabledOnHit?: boolean;
	hideHorizontal?: boolean;
	/**
	 * 用于防止滚动到了边缘
	 */
	safeedge?: number;
};

const Scrollbar = forwardRef<SimpleBar, Prop>(
	(
		{
			children,
			disabledOnHit,
			onHitTop,
			onHitBottom,
			safeedge,
			hideHorizontal,
			...props
		},
		ref
	) => {
		const innerRef = useRef<SimpleBar | null>(null);

		useEffect(() => {
			if (innerRef.current == null) return;
			if (typeof ref === 'function') {
				ref(innerRef.current);
			} else if (ref) {
				ref.current = innerRef.current;
			}
		}, [ref]);

		const { run: listener } = useThrottleFn(
			createOnScroll(
				() => innerRef.current?.getScrollElement(),
				() => innerRef.current?.getContentElement(),
				safeedge ?? 0,
				!!disabledOnHit,
				onHitTop,
				onHitBottom
			),
			{
				wait: 60,
			}
		);

		useEffect(() => {
			if (innerRef.current == null) return;

			const con = innerRef.current.getScrollElement();
			con.addEventListener('scroll', listener);
			return () => con.removeEventListener('scroll', listener);
		}, [listener]);
		return (
			<Container
				hideHorizontal={!!hideHorizontal}
				{...props}
				safeedge={safeedge ?? 0}
				ref={innerRef}
			>
				{children}
			</Container>
		);
	}
);

export const createOnScroll = (
	getCon: () => HTMLElement | null | undefined,
	getViewer: () => HTMLElement | null | undefined,
	safeedge: number,
	disabledOnHit: boolean,
	onHitTop: (() => void | Promise<void>) | undefined,
	onHitBottom: (() => void) | undefined
) => {
	const threshold = Math.max(safeedge * 1.5, 300);
	let lastTop = 0;
	return async function () {
		const con = getCon();
		const viewer = getViewer();
		if (con == null) return;
		if (viewer == null) return;
		const maxScrollTop = () => viewer.clientHeight - con.clientHeight;
		const scrollTop = con.scrollTop;

		const recordScrollTop = () => {
			const _SafeEdge = safeedge;
			if (scrollTop < _SafeEdge) {
				lastTop = con.scrollTop = _SafeEdge;
			} else if (scrollTop > maxScrollTop() - _SafeEdge) {
				lastTop = con.scrollTop = maxScrollTop() - _SafeEdge;
			} else {
				lastTop = scrollTop;
			}
		};
		if (scrollTop === lastTop) {
			recordScrollTop();
			return;
		}
		if (lastTop === 0) {
			recordScrollTop();
			return;
		}

		if (disabledOnHit) {
			recordScrollTop();
			return;
		}
		// is scrolling  up
		if (scrollTop < lastTop) {
			if (scrollTop < threshold) {
				onHitTop?.();
			}
			recordScrollTop();
			return;
		}
		// is scrolling  down
		if (scrollTop > lastTop) {
			if (scrollTop >= maxScrollTop() - threshold) {
				onHitBottom?.();
			}
			recordScrollTop();
			return;
		}
	};
};

export default Scrollbar;
