// routes
import OwnerRouter, { StaffRouter } from './routes';
// theme
// hooks
import useAuth from './hooks/useAuth';
// components
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import QueryClientProvider from './queryclient';

import { ErrorBoundary } from 'react-error-boundary';
import WonderTheme from './wonder-theme';

// ----------------------------------------------------------------------

function fallbackRender({
	error,
	refreshCount,
}: {
	error: Error;
	refreshCount: number;
}) {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.
	if (
		[
			'Failed to fetch dynamically imported module',
			'Unable to preload',
		].find((msg) => error.message.includes(msg)) &&
		refreshCount === 0
	) {
		return <LoadingScreen />;
	}
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre style={{ color: 'red' }}>{error.message}</pre>
		</div>
	);
}

export default function App() {
	const { user, isInitialized } = useAuth();
	const refreshCount = Number(localStorage.getItem('refreshCount')) || 0;

	return (
		<ErrorBoundary
			FallbackComponent={({ error }) =>
				fallbackRender({ error, refreshCount })
			}
			onError={(error) => {
				if (
					[
						'Failed to fetch dynamically imported module',
						'Unable to preload',
					].find((msg) => error.message.includes(msg)) &&
					refreshCount === 0
				) {
					setTimeout(() => {
						localStorage.setItem('refreshCount', '1');
						location.reload();
					}, 500);
				}
			}}
		>
			<WonderTheme>
				<NotistackProvider>
					<QueryClientProvider>
						<>
							<ScrollToTop />
							{isInitialized ? (
								user?.is_admin ? (
									<OwnerRouter />
								) : (
									<StaffRouter />
								)
							) : (
								<LoadingScreen />
							)}
						</>
					</QueryClientProvider>
				</NotistackProvider>
			</WonderTheme>
		</ErrorBoundary>
	);
}
