/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from '@tanstack/react-query';
import type {
	File,
	UrlUpload,
	FileListList200,
	FileListListParams,
	Url,
	PresignUrlParams,
} from '.././model';
import { axiosInstance } from '../../utils/axios';
import type { ErrorType } from '../../utils/axios';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
	config: any,
	args: infer P,
) => any
	? P
	: never;

export const fileDeleteDelete = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{ url: `/file.delete/${id}/`, method: 'post' },
		options,
	);
};

export const getFileDeleteDeleteMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof fileDeleteDelete>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof fileDeleteDelete>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof fileDeleteDelete>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return fileDeleteDelete(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type FileDeleteDeleteMutationResult = NonNullable<
	Awaited<ReturnType<typeof fileDeleteDelete>>
>;

export type FileDeleteDeleteMutationError = ErrorType<unknown>;

export const useFileDeleteDelete = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof fileDeleteDelete>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getFileDeleteDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Create file record after put object to aws
 */
export const fromSignedUrl = (
	urlUpload: UrlUpload,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<File>(
		{
			url: `/file.fromSignedUrl/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: urlUpload,
		},
		options,
	);
};

export const getFromSignedUrlMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof fromSignedUrl>>,
		TError,
		{ data: UrlUpload },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof fromSignedUrl>>,
	TError,
	{ data: UrlUpload },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof fromSignedUrl>>,
		{ data: UrlUpload }
	> = (props) => {
		const { data } = props ?? {};

		return fromSignedUrl(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type FromSignedUrlMutationResult = NonNullable<
	Awaited<ReturnType<typeof fromSignedUrl>>
>;
export type FromSignedUrlMutationBody = UrlUpload;
export type FromSignedUrlMutationError = ErrorType<unknown>;

/**
 * @summary Create file record after put object to aws
 */
export const useFromSignedUrl = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof fromSignedUrl>>,
		TError,
		{ data: UrlUpload },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getFromSignedUrlMutationOptions(options);

	return useMutation(mutationOptions);
};
export const fileListList = (
	params?: FileListListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<FileListList200>(
		{ url: `/file.list/`, method: 'get', params, signal },
		options,
	);
};

export const getFileListListQueryKey = (params?: FileListListParams) =>
	[`/file.list/`, ...(params ? [params] : [])] as const;

export const getFileListListQueryOptions = <
	TData = Awaited<ReturnType<typeof fileListList>>,
	TError = ErrorType<unknown>,
>(
	params?: FileListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof fileListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof fileListList>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getFileListListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof fileListList>>> = ({
		signal,
	}) => fileListList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type FileListListQueryResult = NonNullable<
	Awaited<ReturnType<typeof fileListList>>
>;
export type FileListListQueryError = ErrorType<unknown>;

export const useFileListList = <
	TData = Awaited<ReturnType<typeof fileListList>>,
	TError = ErrorType<unknown>,
>(
	params?: FileListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof fileListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getFileListListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const location = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<unknown>(
		{ url: `/file.location/${id}/`, method: 'get', signal },
		options,
	);
};

export const getLocationQueryKey = (id: string) =>
	[`/file.location/${id}/`] as const;

export const getLocationQueryOptions = <
	TData = Awaited<ReturnType<typeof location>>,
	TError = ErrorType<void>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof location>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof location>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLocationQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof location>>> = ({
		signal,
	}) => location(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type LocationQueryResult = NonNullable<
	Awaited<ReturnType<typeof location>>
>;
export type LocationQueryError = ErrorType<void>;

export const useLocation = <
	TData = Awaited<ReturnType<typeof location>>,
	TError = ErrorType<void>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof location>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getLocationQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * File creating process.    # noqa

1. Get presigned url from this api. (/file.presignUrl/)
2. Upload file via put method to the url you get from the response (aws).

    curl -X PUT --upload-file 192x192.png         "https://wonder-dev-store.s3.amazonaws.com/65BYK4qkGe1637747054771617abccc.png?AWSAccessKeyId=AKIAWEZUVFBZRTVHNNIA&Signature=piXApKmdwt4pge65izFgFTdS3o8%3D&Expires=1637748254"

3. Create file record via (/file.fromSignedUrl/) -H "Content-Type: image/png"

    curl -X POST "http://127.0.0.1:8000/api/v2/app/file.fromSignedUrl/"         -H "accept: application/json"         -H "Content-Type: application/json"         -d '{
          "url": "https://wonder-dev-store.s3.amazonaws.com/65BYK4qkGe1637747054771617abccc.png?AWSAccessKeyId=AKIAWEZUVFBZRTVHNNIA&Signature=piXApKmdwt4pge65izFgFTdS3o8%3D&Expires=1637748254"
        }'

4. Refer as foreign key when you create other objects:

        {
          "product_name": "abcde",
          "image": <the id you get from previous api>
        }

 * @summary Get presigned url (expand to see full proc)
 */
export const presignUrl = (
	params: PresignUrlParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Url>(
		{ url: `/file.presignUrl/`, method: 'get', params, signal },
		options,
	);
};

export const getPresignUrlQueryKey = (params: PresignUrlParams) =>
	[`/file.presignUrl/`, ...(params ? [params] : [])] as const;

export const getPresignUrlQueryOptions = <
	TData = Awaited<ReturnType<typeof presignUrl>>,
	TError = ErrorType<unknown>,
>(
	params: PresignUrlParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof presignUrl>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof presignUrl>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getPresignUrlQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof presignUrl>>> = ({
		signal,
	}) => presignUrl(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type PresignUrlQueryResult = NonNullable<
	Awaited<ReturnType<typeof presignUrl>>
>;
export type PresignUrlQueryError = ErrorType<unknown>;

/**
 * @summary Get presigned url (expand to see full proc)
 */
export const usePresignUrl = <
	TData = Awaited<ReturnType<typeof presignUrl>>,
	TError = ErrorType<unknown>,
>(
	params: PresignUrlParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof presignUrl>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getPresignUrlQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const viewLocationUrl = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Url>(
		{ url: `/file.viewLocationUrl/${id}/`, method: 'get', signal },
		options,
	);
};

export const getViewLocationUrlQueryKey = (id: string) =>
	[`/file.viewLocationUrl/${id}/`] as const;

export const getViewLocationUrlQueryOptions = <
	TData = Awaited<ReturnType<typeof viewLocationUrl>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof viewLocationUrl>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof viewLocationUrl>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getViewLocationUrlQueryKey(id);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof viewLocationUrl>>
	> = ({ signal }) => viewLocationUrl(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ViewLocationUrlQueryResult = NonNullable<
	Awaited<ReturnType<typeof viewLocationUrl>>
>;
export type ViewLocationUrlQueryError = ErrorType<unknown>;

export const useViewLocationUrl = <
	TData = Awaited<ReturnType<typeof viewLocationUrl>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof viewLocationUrl>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getViewLocationUrlQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const fileRead = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<File>(
		{ url: `/file/${id}/`, method: 'get', signal },
		options,
	);
};

export const getFileReadQueryKey = (id: string) => [`/file/${id}/`] as const;

export const getFileReadQueryOptions = <
	TData = Awaited<ReturnType<typeof fileRead>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof fileRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof fileRead>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getFileReadQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof fileRead>>> = ({
		signal,
	}) => fileRead(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type FileReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof fileRead>>
>;
export type FileReadQueryError = ErrorType<unknown>;

export const useFileRead = <
	TData = Awaited<ReturnType<typeof fileRead>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof fileRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getFileReadQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};
