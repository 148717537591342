import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import EmoStyled from '@emotion/styled';
// material
import { alpha, styled } from '@mui/material/styles';
import {
	Box,
	Stack,
	Drawer,
	Tooltip,
	Typography,
	CardActionArea,
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
// components
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import { getSiderbarConfig } from './SidebarConfig';
import { useShopInfoValue } from '@/pages/onboarding-process/model';
import classNames from 'classnames';
import { css } from '@emotion/css';
import Logo from '@/assets/images/logo/logo_rte_wonderlive.white.png';
import { onDesktop, onLaptop } from '@/theme/breakpoints';
import { useNavigate } from 'react-router-dom';
import { ROOTS_DASHBOARD } from '@/routes/paths';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.complex,
		}),
		backgroundColor: '#fff',
	},
}));

const AccountStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(2),
	backgroundColor: '#f5f5f5',
	width: '100%',
}));

const StyledLogoImage = EmoStyled.img`
	width: 142px;
	height: 40px;
`;

// ----------------------------------------------------------------------

type IconCollapseProps = {
	onToggleCollapse: VoidFunction;
	collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
	return (
		<Tooltip title="Mini Menu">
			<CardActionArea
				onClick={onToggleCollapse}
				sx={{
					width: 18,
					height: 18,
					display: 'flex',
					cursor: 'pointer',
					borderRadius: '50%',
					alignItems: 'center',
					color: 'text.primary',
					justifyContent: 'center',
					border: 'solid 1px currentColor',
					...(collapseClick && {
						borderWidth: 2,
					}),
				}}
			>
				<Box
					sx={{
						width: 8,
						height: 8,
						borderRadius: '50%',
						bgcolor: 'currentColor',
						transition: (theme) => theme.transitions.create('all'),
						...(collapseClick && {
							width: 0,
							height: 0,
						}),
					}}
				/>
			</CardActionArea>
		</Tooltip>
	);
}

type DashboardSidebarProps = {
	isOpenSidebar: boolean;
	onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({
	isOpenSidebar,
	onCloseSidebar,
}: DashboardSidebarProps) {
	const { pathname } = useLocation();
	const { user } = useAuth();
	const shopInfo = useShopInfoValue();
	const navigator = useNavigate();

	const {
		isCollapse,
		collapseClick,
		collapseHover,
		onToggleCollapse,
		onHoverEnter,
		onHoverLeave,
	} = useCollapseDrawer();

	const config = getSiderbarConfig(user?.is_admin ?? false);

	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderContent = (
		<Scrollbar>
			<Stack
				spacing={3}
				sx={{
					px: 2.5,
					pt: 3,
					pb: 2,
					...(isCollapse && {
						alignItems: 'center',
					}),
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					{!isCollapse && (
						<StyledLogoImage
							className="pointer"
							onClick={() => navigator(ROOTS_DASHBOARD)}
							src={Logo}
						/>
					)}
					<MHidden width="lgDown">
						{!isCollapse && (
							<IconCollapse
								onToggleCollapse={onToggleCollapse}
								collapseClick={collapseClick}
							/>
						)}
					</MHidden>
				</Stack>

				{isCollapse ? (
					<MyAvatar sx={{ mx: 'auto', mb: 2 }} />
				) : (
					<AccountStyle>
						<MyAvatar sx={{ width: '60px', height: '60px' }} />
						<Box
							sx={{ ml: 1.5 }}
							className={classNames(css`
								width: 110px;
								${onDesktop()} {
									width: 150px;
								}
							`)}
						>
							<Typography
								variant="subtitle2"
								sx={{
									color: 'text.primary',
									fontSize: '16px',
									wordWrap: 'break-word',
									whiteSpace: 'pre-wrap',
								}}
							>
								{shopInfo?.name}
							</Typography>
						</Box>
					</AccountStyle>
				)}
			</Stack>

			<NavSection navConfig={config} isShow={!isCollapse} />

			<Box sx={{ flexGrow: 1 }} />
		</Scrollbar>
	);

	return (
		<RootStyle
			sx={{
				width: {
					lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
				},
				...(collapseClick && {
					position: 'absolute',
				}),
			}}
		>
			<MHidden width="lgUp">
				<Drawer
					open={isOpenSidebar}
					onClose={onCloseSidebar}
					PaperProps={{
						sx: { width: DRAWER_WIDTH },
					}}
				>
					{renderContent}
				</Drawer>
			</MHidden>

			<MHidden width="lgDown">
				<Drawer
					open
					variant="persistent"
					onMouseEnter={onHoverEnter}
					onMouseLeave={onHoverLeave}
					PaperProps={{
						sx: {
							width: DRAWER_WIDTH,
							bgcolor: 'background.paper',
							...(isCollapse && {
								width: COLLAPSE_WIDTH,
							}),
							...(collapseHover && {
								borderRight: 0,
								WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
								boxShadow: (theme) => theme.customShadows.z20,
								bgcolor: (theme) =>
									theme.palette.background.paper,
							}),
						},
					}}
				>
					{renderContent}
				</Drawer>
			</MHidden>
		</RootStyle>
	);
}
