import { datadogRum } from '@datadog/browser-rum';

(() => {
	if (!import.meta.env.PROD) return;
	if (!import.meta.env.VITE_DATADOG_ENV) return;
	datadogRum.init({
		applicationId: 'd03df679-0a0b-4eae-b647-e7fdb290a1bd',
		clientToken: 'pub608f9a4948f80fdaa0f45642bf153650',
		site: 'datadoghq.eu',
		service: 'wonder-seller',
		env: import.meta.env.VITE_DATADOG_ENV,
		version: __DATADOG_RELEASE_TAG__,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		allowedTracingUrls: [
			(url) => url.startsWith(import.meta.env.VITE_API_BASE_URL),
		],
		defaultPrivacyLevel: 'mask-user-input',
	});

	datadogRum.startSessionReplayRecording();
})();
