import LoginForm from '@/components/authentication/login/LoginForm';
import { AuthLayout } from './layout';

// ----------------------------------------------------------------------

export default function Login() {
	return (
		<AuthLayout
			goback={false}
			title="Sign in to Seller Portal"
			note="Enter your details below."
		>
			<LoginForm />
		</AuthLayout>
	);
}
