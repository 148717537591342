import styled from '@emotion/styled';
import { Box, Drawer, DrawerProps, MenuProps, Typography } from '@mui/material';
import React from 'react';

import { Button } from '@/components/@wonder/button';
import { Menu } from '@/components/@wonder/menu';
import useAuth from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ROOTS_DASHBOARD } from '@/routes/paths';
import { useShopInfoValue } from '@/pages/onboarding-process/model';

const DrawerContainer = styled(Drawer)`
	.MuiBackdrop-root.MuiModal-backdrop {
		top: 70px;
	}
	.MuiDrawer-paperAnchorRight {
		width: 280px;
		top: 70px;
		height: calc(100% - 70px);
		padding: 40px 30px;
		box-shadow: none;
	}
`;

const DropMenuContainer = styled(Menu)`
	.MuiMenu-list {
		width: 340px;
		padding: 20px 10px;
	}
`;

const MenuListContainer = styled.div`
	.MuiButton-root.MuiButton-outlined {
		color: #000;
		border-color: rgba(0, 0, 0, 0.5);
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		height: 40px;
		text-transform: none;
		margin-top: 20px;
		width: 240px;
		margin-left: 40px;
	}
	.MuiMenuItem-root {
		padding: 12px 40px;
		.label-text {
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
		}
	}
`;

const MenuList: React.FC<{
	// eslint-disable-next-line @typescript-eslint/ban-types
	onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}> = ({ onClose }) => {
	const { user, logout } = useAuth();
	const shopInfo = useShopInfoValue();

	const onLogout = async () => {
		try {
			await logout?.();
			onClose?.({}, 'backdropClick');
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<MenuListContainer>
			<Box sx={{ my: 1.5, px: 2.5 }}>
				<Typography variant="subtitle1" noWrap>
					{shopInfo?.name}
				</Typography>
				<Typography
					variant="body2"
					sx={{ color: 'text.secondary' }}
					noWrap
				>
					{user?.email}
				</Typography>
			</Box>
			<Button variant="outlined" onClick={onLogout} color="inherit">
				Log Out
			</Button>
		</MenuListContainer>
	);
};

const DropMenu: React.FC<MenuProps> = ({ anchorEl, open, onClose }) => {
	return (
		<DropMenuContainer anchorEl={anchorEl} open={open} onClose={onClose}>
			<MenuList onClose={onClose} />
		</DropMenuContainer>
	);
};

const HeaderMenu: React.FC<MenuProps | DrawerProps> = (props) => {
	return <DropMenu {...(props as MenuProps)} />;
};

export default HeaderMenu;
