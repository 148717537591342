import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { UseFormSetError } from 'react-hook-form';

const Container = styled.form``;

export const WonderForm: React.FC<
	{
		className?: string;
		name?: string;
		children: React.ReactElement[] | React.ReactElement;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setError?: UseFormSetError<any>;
	} & HTMLAttributes<HTMLFormElement>
> = ({ className, setError, children, onSubmit, ...props }) => {
	const containerRef = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		if (containerRef.current == null) return;
		const inputs = containerRef.current.querySelectorAll(
			'input:not(.MuiSelect-nativeInput)'
		);

		if (inputs[0] instanceof HTMLInputElement) {
			inputs[0].focus();
		}
	}, [containerRef]);

	return (
		<Container
			{...props}
			ref={containerRef}
			onSubmit={async (e) => {
				e.preventDefault();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const setLoading = (e.nativeEvent as any)?.submitter
					?.__wonderSetFormLoading__;
				setLoading?.(true);
				try {
					await onSubmit?.(e);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					console.error(error);
					if (typeof error?.response?.data?.detail === 'object') {
						const errorDetail = error.response.data.detail;
						Object.keys(errorDetail).forEach((field) => {
							const message = errorDetail[field][0];
							message &&
								setError?.(field, {
									type: 'server',
									message,
								});
						});
					}
				}
				setLoading?.(false);
			}}
			className={classNames(className)}
		>
			{children}
		</Container>
	);
};
