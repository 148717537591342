import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useInvalidate = () => {
	const queryClient = useQueryClient();
	return (...queryKey: QueryKey) =>
		queryClient.invalidateQueries({
			queryKey,
		});
};

/**
 * for mui menu
 * @returns
 */
export const useMenu = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuVisible = Boolean(anchorEl);
	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const closeMenu = () => {
		setAnchorEl(null);
	};
	return {
		anchorEl,
		menuVisible,
		openMenu,
		closeMenu,
	};
};


export const useRouteMatch = (patterns: string[]) => {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i++) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pathname, pattern);

		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
};