import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
	if (!accessToken) {
		return false;
	}
	const decoded = jwtDecode<{ exp: number }>(accessToken);
	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};


const setSession = (accessToken: string | null) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Token ${accessToken}`;
		// This function below will handle when token is expired
		// const { exp } = jwtDecode(accessToken);
		// handleTokenExpired(exp);
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

export { isValidToken, setSession, verify, sign };
