import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// routes
import { ROOTS_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const navigate = useNavigate();
	const anchorRef = useRef(null);
	const { user, logout } = useAuth();
	const isMountedRef = useIsMountedRef();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = async () => {
		try {
			await logout?.();
			if (isMountedRef.current) {
				handleClose();
			}
		} catch (error: any) {
			console.error(error);
			enqueueSnackbar('Unable to logout', { variant: 'error' });
		}
	};
	return (
		<>
			<MIconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					padding: 0,
					width: 44,
					height: 44,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) =>
								alpha(theme.palette.grey[900], 0.72),
						},
					}),
				}}
			>
				<MyAvatar />
			</MIconButton>

			<MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorRef.current}
				sx={{ width: 220 }}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle1" noWrap>
						{user?.username}
					</Typography>
					<Typography
						variant="body2"
						sx={{ color: 'text.secondary' }}
						noWrap
					>
						{user?.email}
					</Typography>
				</Box>
				<Box sx={{ p: 2, pt: 1.5 }}>
					<Button
						fullWidth
						color="inherit"
						variant="outlined"
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
}
