import { ReactNode } from 'react';
import ThemeConfig from '@/theme';
import ThemePrimaryColor from '@/components/ThemePrimaryColor';
import ThemeLocalization from '@/components/ThemeLocalization';
const WonderTheme: React.FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<ThemeConfig>
			<ThemePrimaryColor>
				<ThemeLocalization>{children}</ThemeLocalization>
			</ThemePrimaryColor>
		</ThemeConfig>
	);
};

export default WonderTheme;
