/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */

export type RegisterUserRegion =
	(typeof RegisterUserRegion)[keyof typeof RegisterUserRegion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegisterUserRegion = {
	US: 'US',
	IE: 'IE',
	GB: 'GB',
} as const;
