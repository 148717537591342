import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';

import HeaderMenu from './menu';
import { HeaderMode } from './model';

import LogoTransparent from '@/assets/images/logo/logo_rte_wonderlive.png';
import Logo from '@/assets/images/logo/logo_rte_wonderlive.white.png';
import MyAvatar from '@/components/MyAvatar';
import { useMenu } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ROOTS_DASHBOARD } from '@/routes/paths';
import { useShopInfoValue } from '@/pages/onboarding-process/model';
import { onPhone } from '@/theme/breakpoints';

const StyledHeadCon = styled.div`
	z-index: 2;
	position: relative;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.5) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	margin-bottom: calc(-1 * var(--header-height));
	color: #fff;
	&.white {
		color: #000;
		background: #ffffff;
		margin-bottom: 0;
	}

	.head-content {
		height: var(--header-height);
		margin: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.header-icon {
		width: 24px;
		height: 24px;
	}

	.userinfo {
		justify-content: flex-end;
		.username {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			margin-right: 4px;
			${onPhone()} {
				display: none;
			}
		}
	}

	.login-button {
		margin-left: 41px;
	}
`;

const StyledLogoImage = styled.img`
	width: 142px;
	height: 40px;
`;

const Header: React.FC<{
	mode: HeaderMode;
	className?: string;
}> = function ({ mode, className }) {
	const isWhiteMode = mode === HeaderMode.white;
	const shopInfo = useShopInfoValue();
	const navigator = useNavigate();

	const {
		anchorEl: userMenuAnchorEl,
		menuVisible: userMenuVisible,
		openMenu: openUserMenu,
		closeMenu: closeUserMenu,
	} = useMenu();

	return (
		<StyledHeadCon
			className={classNames({
				white: isWhiteMode,
			})}
		>
			<div
				className={classNames([
					'head-content',
					'safe-width',
					className,
				])}
			>
				<StyledLogoImage
					className="pointer"
					onClick={() => navigator(ROOTS_DASHBOARD)}
					src={isWhiteMode ? Logo : LogoTransparent}
				/>
				<div className="flex-center">
					<>
						<span className="userinfo flex-center-center">
							<span className="username">{shopInfo?.name}</span>
							<IconButton
								onClick={openUserMenu}
								aria-controls={
									userMenuVisible ? 'account-menu' : undefined
								}
								aria-haspopup="true"
								aria-expanded={
									userMenuVisible ? 'true' : undefined
								}
							>
								<MyAvatar />
							</IconButton>
						</span>
						<HeaderMenu
							anchorEl={userMenuAnchorEl}
							open={userMenuVisible}
							onClose={() => closeUserMenu()}
						/>
					</>
				</div>
			</div>
		</StyledHeadCon>
	);
};

export default Header;
