import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import { useShopInfoValue } from '@/pages/onboarding-process/model';
import DefaultAvatar from '@/assets/images/user/default-avatar.svg';
import { imageQualityController } from '@/utils/image-loader';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
	const shopInfo = useShopInfoValue();

	return (
		<MAvatar
			{...other}
			src={
				imageQualityController(other?.src, 10) ||
				imageQualityController(shopInfo?.avatar_url, 10) ||
				DefaultAvatar
			}
		></MAvatar>
	);
}
