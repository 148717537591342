import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import OnboardingLayout from '@/layouts/OnboardingLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();
	const isDashboard = pathname.includes('/dashboard');

	return (
		<Suspense
			fallback={
				<LoadingScreen
					sx={{
						...(!isDashboard && {
							top: 0,
							left: 0,
							width: 1,
							zIndex: 9999,
							position: 'fixed',
						}),
					}}
				/>
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

// IMPORT COMPONENTS

// Authentication
const IframeShows = Loadable(lazy(() => import('@/pages/iframe-shows')));
const IframeShowDetail = Loadable(
	lazy(() => import('@/pages/iframe-shows/detail'))
);
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(
	lazy(() => import('../pages/authentication/reset-password/reset-password'))
);
const ResetPasswordVerifyEmail = Loadable(
	lazy(() => import('../pages/authentication/reset-password/verify-email'))
);
const NewPassword = Loadable(
	lazy(() => import('../pages/authentication/reset-password/new-password'))
);
const ResetPasswordSuccess = Loadable(
	lazy(() => import('../pages/authentication/reset-password/success'))
);

const Products = Loadable(lazy(() => import('../pages/products')));
const GeneralAnalytics = Loadable(
	lazy(() => import('../pages/products/UploadCsv'))
);
const OrderList = Loadable(lazy(() => import('../pages/orders')));
const OrderDetail = Loadable(
	lazy(() => import('../pages/orders/order-detail'))
);
const GeneralSetting = Loadable(
	lazy(() => import('../pages/setting/SettingStripe'))
);
const SettingPermission = Loadable(
	lazy(() => import('../pages/setting/user-permission'))
);
const SettingStore = Loadable(
	lazy(() => import('../pages/setting/SettingStore'))
);

const AddProduct = Loadable(lazy(() => import('../pages/products/Add')));
const EditProduct = Loadable(lazy(() => import('../pages/products/Edit')));

// // Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const OnboardingProcess = Loadable(
	lazy(() => import('../pages/onboarding-process'))
);
const SellerAgreement = Loadable(
	lazy(() => import('../pages/onboarding-process/seller-agreement'))
);
const StoreInformation = Loadable(
	lazy(() => import('../pages/onboarding-process/store-information'))
);
const StorePolicy = Loadable(
	lazy(() => import('../pages/onboarding-process/store-policy'))
);
const Payout = Loadable(
	lazy(() => import('../pages/onboarding-process/payout'))
);
const OnboardingSuccess = Loadable(
	lazy(() => import('../pages/onboarding-process/success'))
);

const OnboardingRoute = {
	path: 'onboarding-process',
	element: (
		<AuthGuard>
			<OnboardingLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '',
			element: <OnboardingProcess />,
			children: [
				{
					path: '',
					element: <Navigate to="/onboarding-process/agreement" />,
				},
				{ path: 'agreement', element: <SellerAgreement /> },
				{ path: 'information', element: <StoreInformation /> },
				{ path: 'policy', element: <StorePolicy /> },
				{ path: 'payout', element: <Payout /> },
			],
		},
		{ path: 'success', element: <OnboardingSuccess /> },
	],
};

const authRoutes = {
	path: 'auth',
	children: [
		{
			path: 'login',
			element: (
				<GuestGuard>
					<Login />
				</GuestGuard>
			),
		},
		{
			path: 'password/reset',
			element: (
				<GuestGuard>
					<ResetPassword />
				</GuestGuard>
			),
		},
		{
			path: 'password/reset/verify',
			element: (
				<GuestGuard>
					<ResetPasswordVerifyEmail />
				</GuestGuard>
			),
		},
		{
			path: 'password/reset/new',
			element: (
				<GuestGuard>
					<NewPassword />
				</GuestGuard>
			),
		},
		{
			path: 'password/reset/success',
			element: (
				<GuestGuard>
					<ResetPasswordSuccess />
				</GuestGuard>
			),
		},
		{ path: 'login-unprotected', element: <Login /> },
	],
};

export default function OwnerRouter() {
	return useRoutes([
		authRoutes,
		OnboardingRoute,
		{
			path: '/',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ path: '/', element: <Navigate to="/product" /> },
				{ path: '/iframe-shows', element: <IframeShows /> },
				{
					path: '/iframe-shows/:showId',
					element: <IframeShowDetail />,
				},
				{ path: 'product', element: <Products /> },
				{ path: 'product/add', element: <AddProduct /> },
				{ path: 'product/edit/:goodId', element: <EditProduct /> },
				{ path: 'upload', element: <GeneralAnalytics /> },
				{ path: 'order', element: <OrderList /> },
				{ path: 'order/:orderId', element: <OrderDetail /> },
				{
					path: 'setting',
					children: [
						{ path: '', element: <Navigate to="/setting/store" /> },
						{ path: 'store', element: <SettingStore /> },
						{ path: 'stripe', element: <GeneralSetting /> },
						{ path: 'permission', element: <SettingPermission /> },
					],
				},
			],
		},

		// Main Routes
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{ path: '500', element: <Page500 /> },
				{ path: '404', element: <NotFound /> },
				{ path: '*', element: <Navigate to="/404" replace /> },
			],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
	]);
}

export function StaffRouter() {
	return useRoutes([
		authRoutes,
		OnboardingRoute,
		{
			path: '/',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ path: '/', element: <Navigate to="/product" /> },
				{ path: 'product', element: <Products /> },
				{ path: 'product/add', element: <AddProduct /> },
				{ path: 'product/edit/:goodId', element: <EditProduct /> },
				{ path: 'upload', element: <GeneralAnalytics /> },
				{ path: 'order', element: <OrderList /> },
				{ path: 'order/:orderId', element: <OrderDetail /> },
				{
					path: 'setting',
					children: [{ path: 'store', element: <SettingStore /> }],
				},
			],
		},

		// Main Routes
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{ path: '500', element: <Page500 /> },
				{ path: '404', element: <NotFound /> },
				{ path: '*', element: <Navigate to="/404" replace /> },
			],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
	]);
}
