import { useState, ReactNode } from 'react';
import { Icon } from '@iconify/react';
import {
	NavLink as RouterLink,
	matchPath,
	useLocation,
} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
	Box,
	List,
	BoxProps,
	Collapse,
	ListItemText,
	ListItemIcon,
	ListSubheader,
	ListItemButton,
	ListItemButtonProps,
} from '@mui/material';

// ----------------------------------------------------------------------

interface ListItemStyleProps extends ListItemButtonProps {
	component?: React.ElementType;
	to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(
	({ theme }) => ({
		...theme.typography.body2,
		height: 48,
		position: 'relative',
		textTransform: 'capitalize',
		paddingLeft: theme.spacing(5),
		color: theme.palette.text.secondary,
	})
);

const ListItemIconStyle = styled(ListItemIcon)({
	width: 22,
	height: 22,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
	title: string;
	path: string;
	icon?: JSX.Element;
	info?: JSX.Element;
	children?: {
		title: string;
		path: string;
	}[];
};

function NavItem({
	item,
	isShow,
}: {
	item: NavItemProps;
	isShow?: boolean | undefined;
}) {
	const theme = useTheme();
	const { pathname } = useLocation();
	const { title, path, icon, info, children } = item;
	const isActiveRoot = path
		? !!matchPath({ path, end: false }, pathname)
		: false;

	const [open, setOpen] = useState(isActiveRoot);

	const handleOpen = () => {
		setOpen(!open);
	};

	const activeRootStyle = {
		color: 'text.primary',
		fontWeight: 'fontWeightMedium',
		bgcolor: '#f5f5f5',
		'&:before': { display: 'block' },
	};

	const activeSubStyle = {
		color: 'text.primary',
		fontWeight: 'fontWeightMedium',
	};

	if (children) {
		return (
			<>
				<ListItemStyle
					disableGutters
					onClick={handleOpen}
					sx={{
						...(isActiveRoot && activeRootStyle),
					}}
				>
					<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
					{isShow && (
						<>
							<ListItemText disableTypography primary={title} />
							{info && info}
							<Box
								component={Icon}
								icon={
									open
										? arrowIosDownwardFill
										: arrowIosForwardFill
								}
								sx={{ width: 16, height: 16, ml: 1 }}
							/>
						</>
					)}
				</ListItemStyle>

				{isShow && (
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{children.map((item) => {
								const { title, path } = item;
								const isActiveSub = path
									? !!matchPath(
											{ path, end: false },
											pathname
									  )
									: false;

								return (
									<ListItemStyle
										disableGutters
										key={title}
										component={RouterLink}
										to={path}
										sx={{
											...(isActiveSub && activeSubStyle),
										}}
									>
										<ListItemIconStyle>
											<Box
												component="span"
												sx={{ marginRight: -4 }}
											>
												-
											</Box>
										</ListItemIconStyle>
										<ListItemText
											disableTypography
											primary={title}
										/>
									</ListItemStyle>
								);
							})}
						</List>
					</Collapse>
				)}
			</>
		);
	}

	return (
		<ListItemStyle
			disableGutters
			component={RouterLink}
			to={path}
			sx={{
				...(isActiveRoot && activeRootStyle),
			}}
		>
			<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
			{isShow && (
				<>
					<ListItemText disableTypography primary={item.title} />
					{info && info}
				</>
			)}
		</ListItemStyle>
	);
}

interface NavSectionProps extends BoxProps {
	isShow?: boolean | undefined;
	navConfig: {
		subheader: string;
		items: NavItemProps[];
	}[];
}

export default function NavSection({
	navConfig,
	isShow = true,
	...other
}: NavSectionProps) {
	return (
		<Box {...other}>
			{navConfig.map((list) => {
				const { subheader, items } = list;
				return (
					<List key={subheader} disablePadding>
						{items.map((item: NavItemProps) => (
							<NavItem
								key={item.title}
								item={item}
								isShow={isShow}
							/>
						))}
					</List>
				);
			})}
		</Box>
	);
}
