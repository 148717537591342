import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import {
	ActionMap,
	AuthState,
	AuthUser,
	JWTContextType,
} from '../@types/authentication';
import { queryClientRef } from '@/queryclient';
// import { GET_GREETING } from '../utils/query'
// ----------------------------------------------------------------------

enum Types {
	Initial = 'INITIALIZE',
	Login = 'LOGIN',
	Logout = 'LOGOUT',
	Register = 'REGISTER',
}

type JWTAuthPayload = {
	[Types.Initial]: {
		isAuthenticated: boolean;
		user: AuthUser;
	};
	[Types.Login]: {
		user: AuthUser;
	};
	[Types.Logout]: undefined;
	[Types.Register]: {
		user: AuthUser;
	};
};

export type JWTActions =
	ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
	switch (action.type) {
		case 'INITIALIZE':
			return {
				isAuthenticated: action.payload.isAuthenticated,
				isInitialized: true,
				user: action.payload.user,
			};
		case 'LOGIN':
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
			};
		case 'LOGOUT':
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};

		case 'REGISTER':
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
			};

		default:
			return state;
	}
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
	const [state, dispatch] = useReducer(JWTReducer, initialState);
	useEffect(() => {
		const initialize = async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken) {
					setSession(accessToken);
					let temp = localStorage.getItem('user') || '';
					let user = temp ? JSON.parse(temp) : {};
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: true,
							user: user,
						},
					});
				} else {
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err: any) {
				console.error(err);
				dispatch({
					type: Types.Initial,
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const login = async (token: string) => {
		try {
			setSession(token);
		} catch (error: any) {
			console.log(error);
		}
	};

	const register = async (
		email: string,
		password: string,
		firstName: string,
		lastName: string
	) => {
		const response = await axios.post('/api/account/register', {
			email,
			password,
			firstName,
			lastName,
		});
		const { accessToken, user } = response.data;

		window.localStorage.setItem('accessToken', accessToken);
		dispatch({
			type: Types.Register,
			payload: {
				user,
			},
		});
	};

	const logout = async () => {
		setSession(null);
		dispatch({ type: Types.Logout });
		console.log('logout');
		localStorage.removeItem('user');
		queryClientRef?.current.clear();
		window.history.pushState('', '', '/');
		window.location.reload();
	};

	const resetPassword = (email: string) => console.log(email);

	const updateProfile = (user: AuthUser) => {
		localStorage.setItem('user', JSON.stringify(user));
		dispatch({
			type: Types.Login,
			payload: {
				user: {
					...user,
				},
			},
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'jwt',
				login,
				logout,
				register,
				resetPassword,
				updateProfile,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
