// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import liveIcon from '@iconify/icons-ic/baseline-live-tv';

// ----------------------------------------------------------------------
const getIcon = (name: string) => (
	<SvgIconStyle
		src={`/static/icons/navbar/${name}.svg`}
		sx={{ width: '100%', height: '100%' }}
	/>
);

export const getSiderbarConfig = (isAdmin: boolean) => [
	{
		subheader: 'general',
		items: [
			{
				title: 'Products',
				path: PATH_DASHBOARD.general.product.list,
				icon: getIcon('ic_banking'),
			},
			{
				title: 'Orders',
				path: PATH_DASHBOARD.general.order,
				icon: getIcon('ic_ecommerce'),
			},
			{
				title: 'Iframe shows',
				path: PATH_DASHBOARD.general.iframeShows,
				icon: <Icon icon={liveIcon} width={20} height={20} />,
			},
			{
				title: 'Setting',
				path: PATH_DASHBOARD.general.setting,
				icon: <Icon icon={settings2Fill} width={20} height={20} />,
				children: [
					{
						title: 'Store Settings',
						path: PATH_DASHBOARD.general.store,
					},
					...(isAdmin
						? [
								{
									title: 'Payout',
									path: PATH_DASHBOARD.general.stripe,
								},
								{
									title: 'Users & Permissions',
									path: PATH_DASHBOARD.general.permission,
								},
						  ]
						: []),
				],
			},
		],
	},
];
