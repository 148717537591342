import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
	getMallShopQueryKey,
	useMallAddressCreateCreate,
	useMallAddressUpdatePartialUpdate,
	useMallShop,
} from '@/api/address/address';
import { Address, AddressType, RegisterUserRegion } from '@/api/model';
import { useInvalidate } from '@/hooks';

export type AddressForm = Omit<Address, 'type'>;

export const addressYup = {
	country: yup.string().required(),
	name: yup.string().required('Address Name is required'),
	line1: yup.string().required('Shipping Address is required'),
	line2: yup.string(),
	city: yup.string().required('City is required'),
	state: yup.string().when('country', {
		is: (v: RegisterUserRegion) => v === RegisterUserRegion.US,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema,
	}),
	county: yup.string().when('country', {
		is: (v: RegisterUserRegion) => v === RegisterUserRegion.IE,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema,
	}),
	zipcode: yup.string().required('ZIP/Postal code is required'),
};

export const useAddressForm = (defaultValue?: Address | null) => {
	return useForm<AddressForm>({
		mode: 'all',
		defaultValues: {
			...defaultValue,
		},
		resolver: yupResolver(yup.object().shape(addressYup)),
	});
};

export const AddressModel = (function () {
	return {
		useInvalidateAddress(id: string) {
			const invalidate = useInvalidate();
			return () => invalidate(...getMallShopQueryKey(id));
		},
		useAddresses(id: string) {
			const { data: res } = useMallShop(id);
			const address = res?.data.results?.length
				? res?.data.results[0]
				: undefined;
			return {
				address,
			};
		},
		useAddAddress(id: string) {
			const invalidate = this.useInvalidateAddress(id);
			const { mutateAsync, ...result } = useMallAddressCreateCreate({
				mutation: {
					onSuccess() {
						invalidate();
					},
				},
			});
			return {
				async submit(obj: AddressForm) {
					const { data } = await mutateAsync({
						data: {
							...obj,
							type: AddressType.shop,
							entity_id: id,
						},
					});

					return data;
				},
				...result,
			};
		},
		useEditAddress(id: string) {
			const invalidate = this.useInvalidateAddress(id);
			const { mutateAsync, ...result } =
				useMallAddressUpdatePartialUpdate({
					mutation: {
						onSuccess() {
							invalidate();
						},
					},
				});
			return {
				async submit(id: string, obj: AddressForm) {
					const { data } = await mutateAsync({
						id,
						data: obj,
					});
					return data;
				},
				...result,
			};
		},
	};
})();
