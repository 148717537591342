// i18n
import '@/utils/rem';
import '@/utils/datadog';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'react-image-lightbox/style.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as JotaiProvider } from 'jotai';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { AuthProvider } from './contexts/JWTContext';

import '@/assets/styles/global.css';

import App from './App';
import React from 'react';

// ----------------------------------------------------------------------

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);

root.render(
	//@ts-ignore
	<React.StrictMode>
		<JotaiProvider>
			<SettingsProvider>
				<CollapseDrawerProvider>
					<BrowserRouter>
						<AuthProvider>
							<App />
						</AuthProvider>
					</BrowserRouter>
				</CollapseDrawerProvider>
			</SettingsProvider>
		</JotaiProvider>
	</React.StrictMode>
);
