import { OnboardingModel } from '@/pages/onboarding-process/model';
import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import LoadingScreen from '@/components/LoadingScreen';

// ----------------------------------------------------------------------

type AuthGuardProps = {
	children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
	const { isAuthenticated, user } = useAuth();
	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState<string | null>(
		null
	);
	const { isFetching, refetch } = OnboardingModel.useGetShopInfo(
		user?.shop_id
	);

	useEffect(() => {
		if (isAuthenticated) {
			refetch();
		}
	}, [user]);

	if (!isAuthenticated) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		return <Login />;
	}

	if (isFetching) return <LoadingScreen />;

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
