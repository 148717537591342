import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
	return {
		MuiIconButton: {
			styleOverrides: {
				root: {},
				sizeSmall: {
					fontSize: 14,
				},
				sizeMedium: {
					fontSize: 20,
				},
				sizeLarge: {
					fontSize: 24,
				},
			},
		},
	};
}
