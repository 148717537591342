import _ from 'lodash';

import { breakpoints } from '@/theme/breakpoints';

const log = import.meta.env.PROD
	? null
	: _.throttle((type: string) => {
		console.log(type);
	}, 250);
(() => {
	function listner() {
		const outerWidth = document.documentElement.clientWidth;
		let basis: number;

		/**
		 * Keep root font-size as 1px on desktop and laptop,
		 * use dynamic font-size on pad and phone.
		 */
		document.documentElement.style.fontSize = '1px';

		if (outerWidth >= breakpoints.xl) {
			log?.('desktop');
			return;
		} else if (outerWidth >= breakpoints.lg) {
			log?.('laptop');
			return;
		}

		if (outerWidth >= breakpoints.md) {
			log?.('pad');
			basis = breakpoints.md;
		} else {
			log?.('phone');
			basis = breakpoints.sm;
		}

		const w = outerWidth / basis; //获取视口大小
		document.documentElement.style.fontSize = `${w}px`;
	}
	listner();
	window.addEventListener('resize', _.debounce(listner, 60));
})();

export { };
