import { Outlet } from 'react-router-dom';
// material
import { styled as MuiStyled, useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

import Header from '@/components/header';
import { HeaderMode } from '@/components/header/model';
import { onPhone } from '@/theme/breakpoints';
import { useEffect } from 'react';

const RootStyle = styled.div`
	display: flex;
	min-height: 100%;
	overflow: hidden;
	${onPhone()} {
		padding: 0 15px;
		.main-style {
			padding-bottom: 30px;
		}
	}
`;

const MainStyle = MuiStyled('div')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up('lg')]: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

const LayoutStyle = styled.div`
	.pointer {
		pointer-events: none;
	}
`;
// ----------------------------------------------------------------------

export default function OnboardingLayout() {
	const theme = useTheme();

	useEffect(() => {
		localStorage.removeItem('refreshCount');
	}, []);

	return (
		<LayoutStyle>
			<Header mode={HeaderMode.white} />
			<RootStyle className="safe-width margin-auto">
				<MainStyle
					className="main-style"
					sx={{
						transition: theme.transitions.create('margin', {
							duration: theme.transitions.duration.complex,
						}),
					}}
				>
					<Outlet />
				</MainStyle>
			</RootStyle>
		</LayoutStyle>
	);
}
