import { MIconButton } from '../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { VariantType } from 'notistack';

export default function snackBarOption(variant: VariantType, close: any) {
	return {
		variant,
		action: (key: any) => (
			<MIconButton size="small" onClick={() => close(key)}>
				<Icon icon={closeFill} />
			</MIconButton>
		),
	};
}
