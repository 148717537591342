/**
 * image quality controller
 * @param src
 * @param quality quality of a image, from 0 to 100.
 */
export const imageQualityController = (src?: string, quality = 20) => {
	if (src == null) return '';
	if (typeof src !== 'string') {
		throw new Error('image url should be a string!!');
	}
	if (src.trim() === '') return src;
	if (!src?.startsWith('http')) {
		src = `${import.meta.env.VITE_API_BASE_URL}/file.location/${src}`;
	}
	if (quality >= 100) return src;
	quality = Math.max(0, quality);
	return `${src}?quality=${quality}`;
};
