/* eslint-disable */
// @ts-nocheck
/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Wonder API
 * wonder documentation
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from '@tanstack/react-query';
import type {
	Email,
	Url,
	ConnectToStripeInput,
	Shop,
	ShopAvatar,
	ShopAvatarInput,
	ShopInfo,
	MallShopGetMyListListParams,
	ShopSaleStats,
	MallShopGetShopStatsReadParams,
	MallShopListList200,
	MallShopListListParams,
	MallMemberInfo200,
	MallMemberInfoParams,
	SimpleUser,
	UserId,
	MallShopSearchList200,
	MallShopSearchListParams,
	PatchShopForSeller,
	ShopAgreement,
	PatchShopForAdmin,
	ShopUpdateInfoInput,
	ShopUpdatePolicyInput,
	OrderDetailResponse,
} from '.././model';
import { axiosInstance } from '../../utils/axios';
import type { ErrorType } from '../../utils/axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
	T,
>() => T extends Y ? 1 : 2
	? A
	: B;

type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<
		{ [Q in P]: T[P] },
		{ -readonly [Q in P]: T[P] },
		P
	>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
	k: infer I,
) => void
	? I
	: never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
	? {
			[P in keyof Writable<T>]: T[P] extends object
				? NonReadonly<NonNullable<T[P]>>
				: T[P];
		}
	: DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
	config: any,
	args: infer P,
) => any
	? P
	: never;

/**
 * @summary Add staff
 */
export const mallAddStaff = (
	id: string,
	email: Email,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{
			url: `/mall/shop.addStaff/${id}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: email,
		},
		options,
	);
};

export const getMallAddStaffMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddStaff>>,
		TError,
		{ id: string; data: Email },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallAddStaff>>,
	TError,
	{ id: string; data: Email },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallAddStaff>>,
		{ id: string; data: Email }
	> = (props) => {
		const { id, data } = props ?? {};

		return mallAddStaff(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallAddStaffMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallAddStaff>>
>;
export type MallAddStaffMutationBody = Email;
export type MallAddStaffMutationError = ErrorType<unknown>;

/**
 * @summary Add staff
 */
export const useMallAddStaff = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallAddStaff>>,
		TError,
		{ id: string; data: Email },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallAddStaffMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallShopConnectToStripeCreate = (
	shopId: string,
	connectToStripeInput: ConnectToStripeInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Url>(
		{
			url: `/mall/shop.connect_to_stripe/${shopId}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: connectToStripeInput,
		},
		options,
	);
};

export const getMallShopConnectToStripeCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopConnectToStripeCreate>>,
		TError,
		{ shopId: string; data: ConnectToStripeInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopConnectToStripeCreate>>,
	TError,
	{ shopId: string; data: ConnectToStripeInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopConnectToStripeCreate>>,
		{ shopId: string; data: ConnectToStripeInput }
	> = (props) => {
		const { shopId, data } = props ?? {};

		return mallShopConnectToStripeCreate(shopId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopConnectToStripeCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopConnectToStripeCreate>>
>;
export type MallShopConnectToStripeCreateMutationBody = ConnectToStripeInput;
export type MallShopConnectToStripeCreateMutationError = ErrorType<unknown>;

export const useMallShopConnectToStripeCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopConnectToStripeCreate>>,
		TError,
		{ shopId: string; data: ConnectToStripeInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopConnectToStripeCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallShopCreateCreate = (
	shop: NonReadonly<Shop>,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Shop>(
		{
			url: `/mall/shop.create/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: shop,
		},
		options,
	);
};

export const getMallShopCreateCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopCreateCreate>>,
		TError,
		{ data: NonReadonly<Shop> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopCreateCreate>>,
	TError,
	{ data: NonReadonly<Shop> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopCreateCreate>>,
		{ data: NonReadonly<Shop> }
	> = (props) => {
		const { data } = props ?? {};

		return mallShopCreateCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopCreateCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopCreateCreate>>
>;
export type MallShopCreateCreateMutationBody = NonReadonly<Shop>;
export type MallShopCreateCreateMutationError = ErrorType<unknown>;

export const useMallShopCreateCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopCreateCreate>>,
		TError,
		{ data: NonReadonly<Shop> },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallShopCreateCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallShopGetAvatarByIdsCreate = (
	shopAvatarInput: ShopAvatarInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<ShopAvatar[]>(
		{
			url: `/mall/shop.getAvatarByIds/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: shopAvatarInput,
		},
		options,
	);
};

export const getMallShopGetAvatarByIdsCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopGetAvatarByIdsCreate>>,
		TError,
		{ data: ShopAvatarInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopGetAvatarByIdsCreate>>,
	TError,
	{ data: ShopAvatarInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopGetAvatarByIdsCreate>>,
		{ data: ShopAvatarInput }
	> = (props) => {
		const { data } = props ?? {};

		return mallShopGetAvatarByIdsCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopGetAvatarByIdsCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopGetAvatarByIdsCreate>>
>;
export type MallShopGetAvatarByIdsCreateMutationBody = ShopAvatarInput;
export type MallShopGetAvatarByIdsCreateMutationError = ErrorType<unknown>;

export const useMallShopGetAvatarByIdsCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopGetAvatarByIdsCreate>>,
		TError,
		{ data: ShopAvatarInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopGetAvatarByIdsCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Get shop info for seller.
 */
export const mallShopGetInfoRead = (
	shopId: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<ShopInfo>(
		{ url: `/mall/shop.getInfo/${shopId}/`, method: 'get', signal },
		options,
	);
};

export const getMallShopGetInfoReadQueryKey = (shopId: string) =>
	[`/mall/shop.getInfo/${shopId}/`] as const;

export const getMallShopGetInfoReadQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopGetInfoRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetInfoRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopGetInfoRead>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallShopGetInfoReadQueryKey(shopId);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopGetInfoRead>>
	> = ({ signal }) => mallShopGetInfoRead(shopId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!shopId, ...queryOptions };
};

export type MallShopGetInfoReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopGetInfoRead>>
>;
export type MallShopGetInfoReadQueryError = ErrorType<unknown>;

/**
 * @summary Get shop info for seller.
 */
export const useMallShopGetInfoRead = <
	TData = Awaited<ReturnType<typeof mallShopGetInfoRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetInfoRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopGetInfoReadQueryOptions(shopId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallShopGetMyListList = (
	params?: MallShopGetMyListListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Shop[]>(
		{ url: `/mall/shop.getMyList/`, method: 'get', params, signal },
		options,
	);
};

export const getMallShopGetMyListListQueryKey = (
	params?: MallShopGetMyListListParams,
) => [`/mall/shop.getMyList/`, ...(params ? [params] : [])] as const;

export const getMallShopGetMyListListQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopGetMyListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopGetMyListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetMyListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopGetMyListList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallShopGetMyListListQueryKey(params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopGetMyListList>>
	> = ({ signal }) => mallShopGetMyListList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallShopGetMyListListQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopGetMyListList>>
>;
export type MallShopGetMyListListQueryError = ErrorType<unknown>;

export const useMallShopGetMyListList = <
	TData = Awaited<ReturnType<typeof mallShopGetMyListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopGetMyListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetMyListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopGetMyListListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallShopGetShopStatsRead = (
	shopId: string,
	params: MallShopGetShopStatsReadParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<ShopSaleStats>(
		{
			url: `/mall/shop.getShopStats/${shopId}/`,
			method: 'get',
			params,
			signal,
		},
		options,
	);
};

export const getMallShopGetShopStatsReadQueryKey = (
	shopId: string,
	params: MallShopGetShopStatsReadParams,
) =>
	[
		`/mall/shop.getShopStats/${shopId}/`,
		...(params ? [params] : []),
	] as const;

export const getMallShopGetShopStatsReadQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopGetShopStatsRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	params: MallShopGetShopStatsReadParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetShopStatsRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopGetShopStatsRead>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ??
		getMallShopGetShopStatsReadQueryKey(shopId, params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopGetShopStatsRead>>
	> = ({ signal }) =>
		mallShopGetShopStatsRead(shopId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!shopId, ...queryOptions };
};

export type MallShopGetShopStatsReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopGetShopStatsRead>>
>;
export type MallShopGetShopStatsReadQueryError = ErrorType<unknown>;

export const useMallShopGetShopStatsRead = <
	TData = Awaited<ReturnType<typeof mallShopGetShopStatsRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	params: MallShopGetShopStatsReadParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopGetShopStatsRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopGetShopStatsReadQueryOptions(
		shopId,
		params,
		options,
	);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallShopListList = (
	params?: MallShopListListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallShopListList200>(
		{ url: `/mall/shop.list/`, method: 'get', params, signal },
		options,
	);
};

export const getMallShopListListQueryKey = (params?: MallShopListListParams) =>
	[`/mall/shop.list/`, ...(params ? [params] : [])] as const;

export const getMallShopListListQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopListList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallShopListListQueryKey(params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopListList>>
	> = ({ signal }) => mallShopListList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallShopListListQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopListList>>
>;
export type MallShopListListQueryError = ErrorType<unknown>;

export const useMallShopListList = <
	TData = Awaited<ReturnType<typeof mallShopListList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopListListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopListList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopListListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get shop owner info
 */
export const mallMemberInfo = (
	id: string,
	params?: MallMemberInfoParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallMemberInfo200>(
		{ url: `/mall/shop.memberInfo/${id}/`, method: 'get', params, signal },
		options,
	);
};

export const getMallMemberInfoQueryKey = (
	id: string,
	params?: MallMemberInfoParams,
) => [`/mall/shop.memberInfo/${id}/`, ...(params ? [params] : [])] as const;

export const getMallMemberInfoQueryOptions = <
	TData = Awaited<ReturnType<typeof mallMemberInfo>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	params?: MallMemberInfoParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallMemberInfo>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallMemberInfo>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallMemberInfoQueryKey(id, params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallMemberInfo>>
	> = ({ signal }) => mallMemberInfo(id, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type MallMemberInfoQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallMemberInfo>>
>;
export type MallMemberInfoQueryError = ErrorType<unknown>;

/**
 * @summary Get shop owner info
 */
export const useMallMemberInfo = <
	TData = Awaited<ReturnType<typeof mallMemberInfo>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	params?: MallMemberInfoParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallMemberInfo>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallMemberInfoQueryOptions(id, params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get shop owner info
 */
export const mallOwnerInfo = (
	id: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<SimpleUser>(
		{ url: `/mall/shop.ownerInfo/${id}/`, method: 'get', signal },
		options,
	);
};

export const getMallOwnerInfoQueryKey = (id: string) =>
	[`/mall/shop.ownerInfo/${id}/`] as const;

export const getMallOwnerInfoQueryOptions = <
	TData = Awaited<ReturnType<typeof mallOwnerInfo>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallOwnerInfo>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof mallOwnerInfo>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallOwnerInfoQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof mallOwnerInfo>>> = ({
		signal,
	}) => mallOwnerInfo(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type MallOwnerInfoQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallOwnerInfo>>
>;
export type MallOwnerInfoQueryError = ErrorType<unknown>;

/**
 * @summary Get shop owner info
 */
export const useMallOwnerInfo = <
	TData = Awaited<ReturnType<typeof mallOwnerInfo>>,
	TError = ErrorType<unknown>,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallOwnerInfo>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallOwnerInfoQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Revoke access
 */
export const mallRevokeAccess = (
	id: string,
	userId: UserId,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<void>(
		{
			url: `/mall/shop.revokeAccess/${id}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: userId,
		},
		options,
	);
};

export const getMallRevokeAccessMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallRevokeAccess>>,
		TError,
		{ id: string; data: UserId },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallRevokeAccess>>,
	TError,
	{ id: string; data: UserId },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallRevokeAccess>>,
		{ id: string; data: UserId }
	> = (props) => {
		const { id, data } = props ?? {};

		return mallRevokeAccess(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallRevokeAccessMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallRevokeAccess>>
>;
export type MallRevokeAccessMutationBody = UserId;
export type MallRevokeAccessMutationError = ErrorType<unknown>;

/**
 * @summary Revoke access
 */
export const useMallRevokeAccess = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallRevokeAccess>>,
		TError,
		{ id: string; data: UserId },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallRevokeAccessMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Search shop by keyword for buyer.
 */
export const mallShopSearchList = (
	params?: MallShopSearchListParams,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<MallShopSearchList200>(
		{ url: `/mall/shop.search/`, method: 'get', params, signal },
		options,
	);
};

export const getMallShopSearchListQueryKey = (
	params?: MallShopSearchListParams,
) => [`/mall/shop.search/`, ...(params ? [params] : [])] as const;

export const getMallShopSearchListQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopSearchList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopSearchListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopSearchList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopSearchList>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallShopSearchListQueryKey(params);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopSearchList>>
	> = ({ signal }) => mallShopSearchList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions };
};

export type MallShopSearchListQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopSearchList>>
>;
export type MallShopSearchListQueryError = ErrorType<unknown>;

/**
 * @summary Search shop by keyword for buyer.
 */
export const useMallShopSearchList = <
	TData = Awaited<ReturnType<typeof mallShopSearchList>>,
	TError = ErrorType<unknown>,
>(
	params?: MallShopSearchListParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopSearchList>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopSearchListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Update shop for seller.
 */
export const updateShopBySeller = (
	shopId: string,
	patchShopForSeller: PatchShopForSeller,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Shop>(
		{
			url: `/mall/shop.update/${shopId}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: patchShopForSeller,
		},
		options,
	);
};

export const getUpdateShopBySellerMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof updateShopBySeller>>,
		TError,
		{ shopId: string; data: PatchShopForSeller },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof updateShopBySeller>>,
	TError,
	{ shopId: string; data: PatchShopForSeller },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof updateShopBySeller>>,
		{ shopId: string; data: PatchShopForSeller }
	> = (props) => {
		const { shopId, data } = props ?? {};

		return updateShopBySeller(shopId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateShopBySellerMutationResult = NonNullable<
	Awaited<ReturnType<typeof updateShopBySeller>>
>;
export type UpdateShopBySellerMutationBody = PatchShopForSeller;
export type UpdateShopBySellerMutationError = ErrorType<unknown>;

/**
 * @summary Update shop for seller.
 */
export const useUpdateShopBySeller = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof updateShopBySeller>>,
		TError,
		{ shopId: string; data: PatchShopForSeller },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getUpdateShopBySellerMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Update shop agreement for seller.
 */
export const mallShopUpdateAgreementCreate = (
	shopId: string,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<ShopAgreement>(
		{ url: `/mall/shop.updateAgreement/${shopId}/`, method: 'post' },
		options,
	);
};

export const getMallShopUpdateAgreementCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateAgreementCreate>>,
		TError,
		{ shopId: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopUpdateAgreementCreate>>,
	TError,
	{ shopId: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopUpdateAgreementCreate>>,
		{ shopId: string }
	> = (props) => {
		const { shopId } = props ?? {};

		return mallShopUpdateAgreementCreate(shopId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopUpdateAgreementCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopUpdateAgreementCreate>>
>;

export type MallShopUpdateAgreementCreateMutationError = ErrorType<unknown>;

/**
 * @summary Update shop agreement for seller.
 */
export const useMallShopUpdateAgreementCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateAgreementCreate>>,
		TError,
		{ shopId: string },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopUpdateAgreementCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Update shop for admin.
 */
export const mallShopUpdateByAdminCreate = (
	shopId: string,
	patchShopForAdmin: PatchShopForAdmin,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<Shop>(
		{
			url: `/mall/shop.updateByAdmin/${shopId}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: patchShopForAdmin,
		},
		options,
	);
};

export const getMallShopUpdateByAdminCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateByAdminCreate>>,
		TError,
		{ shopId: string; data: PatchShopForAdmin },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopUpdateByAdminCreate>>,
	TError,
	{ shopId: string; data: PatchShopForAdmin },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopUpdateByAdminCreate>>,
		{ shopId: string; data: PatchShopForAdmin }
	> = (props) => {
		const { shopId, data } = props ?? {};

		return mallShopUpdateByAdminCreate(shopId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopUpdateByAdminCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopUpdateByAdminCreate>>
>;
export type MallShopUpdateByAdminCreateMutationBody = PatchShopForAdmin;
export type MallShopUpdateByAdminCreateMutationError = ErrorType<unknown>;

/**
 * @summary Update shop for admin.
 */
export const useMallShopUpdateByAdminCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateByAdminCreate>>,
		TError,
		{ shopId: string; data: PatchShopForAdmin },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopUpdateByAdminCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Update shop info for seller.
 */
export const mallShopUpdateInfoCreate = (
	shopId: string,
	shopUpdateInfoInput: ShopUpdateInfoInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<ShopInfo>(
		{
			url: `/mall/shop.updateInfo/${shopId}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: shopUpdateInfoInput,
		},
		options,
	);
};

export const getMallShopUpdateInfoCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateInfoCreate>>,
		TError,
		{ shopId: string; data: ShopUpdateInfoInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopUpdateInfoCreate>>,
	TError,
	{ shopId: string; data: ShopUpdateInfoInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopUpdateInfoCreate>>,
		{ shopId: string; data: ShopUpdateInfoInput }
	> = (props) => {
		const { shopId, data } = props ?? {};

		return mallShopUpdateInfoCreate(shopId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopUpdateInfoCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopUpdateInfoCreate>>
>;
export type MallShopUpdateInfoCreateMutationBody = ShopUpdateInfoInput;
export type MallShopUpdateInfoCreateMutationError = ErrorType<unknown>;

/**
 * @summary Update shop info for seller.
 */
export const useMallShopUpdateInfoCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdateInfoCreate>>,
		TError,
		{ shopId: string; data: ShopUpdateInfoInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions = getMallShopUpdateInfoCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Update shop policy for seller.
 */
export const mallShopUpdatePolicyCreate = (
	shopId: string,
	shopUpdatePolicyInput: ShopUpdatePolicyInput,
	options?: SecondParameter<typeof axiosInstance>,
) => {
	return axiosInstance<ShopInfo>(
		{
			url: `/mall/shop.updatePolicy/${shopId}/`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: shopUpdatePolicyInput,
		},
		options,
	);
};

export const getMallShopUpdatePolicyCreateMutationOptions = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdatePolicyCreate>>,
		TError,
		{ shopId: string; data: ShopUpdatePolicyInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof mallShopUpdatePolicyCreate>>,
	TError,
	{ shopId: string; data: ShopUpdatePolicyInput },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } =
		options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof mallShopUpdatePolicyCreate>>,
		{ shopId: string; data: ShopUpdatePolicyInput }
	> = (props) => {
		const { shopId, data } = props ?? {};

		return mallShopUpdatePolicyCreate(shopId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MallShopUpdatePolicyCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof mallShopUpdatePolicyCreate>>
>;
export type MallShopUpdatePolicyCreateMutationBody = ShopUpdatePolicyInput;
export type MallShopUpdatePolicyCreateMutationError = ErrorType<unknown>;

/**
 * @summary Update shop policy for seller.
 */
export const useMallShopUpdatePolicyCreate = <
	TError = ErrorType<unknown>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof mallShopUpdatePolicyCreate>>,
		TError,
		{ shopId: string; data: ShopUpdatePolicyInput },
		TContext
	>;
	request?: SecondParameter<typeof axiosInstance>;
}) => {
	const mutationOptions =
		getMallShopUpdatePolicyCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
export const mallShopRead = (
	shopId: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<Shop>(
		{ url: `/mall/shop/${shopId}/`, method: 'get', signal },
		options,
	);
};

export const getMallShopReadQueryKey = (shopId: string) =>
	[`/mall/shop/${shopId}/`] as const;

export const getMallShopReadQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof mallShopRead>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getMallShopReadQueryKey(shopId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof mallShopRead>>> = ({
		signal,
	}) => mallShopRead(shopId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!shopId, ...queryOptions };
};

export type MallShopReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopRead>>
>;
export type MallShopReadQueryError = ErrorType<unknown>;

export const useMallShopRead = <
	TData = Awaited<ReturnType<typeof mallShopRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopReadQueryOptions(shopId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};

export const mallShopOrderRead = (
	shopId: string,
	orderId: string,
	options?: SecondParameter<typeof axiosInstance>,
	signal?: AbortSignal,
) => {
	return axiosInstance<OrderDetailResponse>(
		{
			url: `/mall/shop/${shopId}/order/${orderId}/`,
			method: 'get',
			signal,
		},
		options,
	);
};

export const getMallShopOrderReadQueryKey = (shopId: string, orderId: string) =>
	[`/mall/shop/${shopId}/order/${orderId}/`] as const;

export const getMallShopOrderReadQueryOptions = <
	TData = Awaited<ReturnType<typeof mallShopOrderRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	orderId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopOrderRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryOptions<
	Awaited<ReturnType<typeof mallShopOrderRead>>,
	TError,
	TData
> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey =
		queryOptions?.queryKey ?? getMallShopOrderReadQueryKey(shopId, orderId);

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof mallShopOrderRead>>
	> = ({ signal }) =>
		mallShopOrderRead(shopId, orderId, requestOptions, signal);

	return {
		queryKey,
		queryFn,
		enabled: !!(shopId && orderId),
		...queryOptions,
	};
};

export type MallShopOrderReadQueryResult = NonNullable<
	Awaited<ReturnType<typeof mallShopOrderRead>>
>;
export type MallShopOrderReadQueryError = ErrorType<unknown>;

export const useMallShopOrderRead = <
	TData = Awaited<ReturnType<typeof mallShopOrderRead>>,
	TError = ErrorType<unknown>,
>(
	shopId: string,
	orderId: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof mallShopOrderRead>>,
			TError,
			TData
		>;
		request?: SecondParameter<typeof axiosInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getMallShopOrderReadQueryOptions(
		shopId,
		orderId,
		options,
	);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey;
	};

	query.queryKey = queryOptions.queryKey;

	return query;
};
