import React from 'react';

import Page from '@/components/Page';
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Stack, Typography } from '@mui/material';
import { GoBackLogin } from './goback';
import { css } from '@emotion/css';
const RootStyle = styled(Page)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
	backgroundColor: '#fff',
}));
const ContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	display: 'flex',
	minHeight: '100vh',
	flexDirection: 'column',
	justifyContent: 'center',
	padding: theme.spacing(12, 0),
}));
export const AuthLayout: React.FC<{
	children?: React.ReactNode;
	title: string;
	note: string | React.ReactNode;
	goback?: boolean;
}> = ({ goback, children, title, note }) => {
	goback = goback ?? true;
	return (
		<RootStyle
			title="Login | Wonder Seller Portal"
			className={css`
				.wonder-form-item + .wonder-form-item {
					margin-top: 30px;
				}
			`}
		>
			<Container maxWidth="sm">
				<ContentStyle>
					<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography variant="h4" gutterBottom>
								{title}
							</Typography>
							<Typography sx={{ color: 'text.secondary' }}>
								{note}
							</Typography>
						</Box>
					</Stack>
					{children}
					{goback && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							sx={{ my: 2 }}
						>
							<GoBackLogin />
						</Stack>
					)}
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};
