import useAuth from '@/hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	PatchShopForSeller,
	ShopShippingI18N,
	ShopShippingI18NRegion,
} from '@/api/model';
import { pick } from 'lodash';
import {
	getMallShopReadQueryKey,
	useMallShopRead,
	useUpdateShopBySeller,
} from '@/api/shop/shop';
import { useInvalidate } from '@/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { OnboardingModel } from '@/pages/onboarding-process/model';
import { atom, useAtom, useAtomValue } from 'jotai';
import { upload } from '@/utils/upload';

const isUploadLoading = atom<boolean>(false);
export const useUploadLoadingValue = () =>
	useAtomValue<boolean>(isUploadLoading);

export const useLoading = () => {
	const [, setLoading] = useAtom(isUploadLoading);
	return setLoading;
};

export type ShippingValue = ShopShippingI18N[];

export const infoYup = {
	name: yup.string().required('Store Name is required'),
	description: yup.string(),
	avatar_id: yup.string().required('Store Avatar is required'),
	email: yup
		.string()
		.email('Contact Email must be a valid email')
		.required('Contact Email is required'),
};

export const shippingFeeAmountYup = yup
	.number()
	.typeError('Shipping Fee required number')
	.min(0, 'Shipping Fee must be no less than 0')
	.test(
		'maxDigitsAfterDecimal',
		'Shipping Fee limited to 2 decimals',
		(number) => /^[1-9]\d*(\.\d{1,2})?$|^0(\.\d{1,2})?$/.test(`${number}`)
	);

export const freeShippingMinRequiredAmountYup = yup
	.number()
	.typeError('Free shipping for order over required number')
	.min(0, 'Free shipping for order over must be no less than 0')
	.test(
		'maxDigitsAfterDecimal',
		'Free shipping for order over limited to 2 decimals',
		(number) => /^[1-9]\d*(\.\d{1,2})?$|^0(\.\d{1,2})?$/.test(`${number}`)
	)
	.when(['offer_free_shipping'], ([offer_free_shipping], schema) => {
		if (offer_free_shipping) {
			return schema
				.typeError('Free shipping for order over required number')
				.required('Free shipping for order over required');
		}
		return schema;
	});

const shippingYup = {
	shipping_fee_amount: shippingFeeAmountYup,
	offer_free_shipping: yup.boolean(),
	free_shipping_min_required_amount: freeShippingMinRequiredAmountYup,
};

const infoKeys = ['name', 'description', 'avatar_id', 'email', 'policy'];
const shippingKeys = [
	'shipping_fee_amount',
	'offer_free_shipping',
	'free_shipping_min_required_amount',
];

export enum StoreFormType {
	Store = 'store',
	Shipping = 'shipping',
}

export const useStoreForm = (
	type: StoreFormType,
	defaultValue?: PatchShopForSeller | null
) => {
	const {
		reset,
		register,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<PatchShopForSeller>({
		mode: 'all',
		defaultValues: {
			...pick(
				defaultValue,
				type === StoreFormType.Store ? infoKeys : shippingKeys
			),
		},
		resolver: yupResolver(
			yup
				.object()
				.shape(
					type === StoreFormType.Store
						? { ...infoYup, policy: yup.string() }
						: { ...shippingYup }
				)
		),
	});
	useEffect(() => {
		if (defaultValue && type === StoreFormType.Store) {
			setValue('avatar_id', defaultValue?.avatar_id);
			setValue('name', defaultValue?.name);
			setValue('email', defaultValue?.email);
			setValue('description', defaultValue?.description);
			setValue('policy', defaultValue?.policy);
		}
	}, [defaultValue]);
	return {
		reset,
		register,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors },
	};
};

export const StoreModel = (function () {
	return {
		useInvalidateStore() {
			const { user } = useAuth();
			const invalidate = useInvalidate();
			return () => invalidate(...getMallShopReadQueryKey(user?.shop_id));
		},
		useStore(id: string) {
			const { data: res, ...result } = useMallShopRead(id);
			return {
				storeDetail: res?.data,
				...result,
			};
		},
		useUpdateStore(isShipping?: boolean) {
			const { user } = useAuth();
			const invalidate = this.useInvalidateStore();
			const { refetch } = OnboardingModel.useGetShopInfo(user?.shop_id);
			const { mutateAsync: submit, ...result } = useUpdateShopBySeller({
				mutation: {
					onSuccess({ data }) {
						if (!isShipping) {
							invalidate();
							refetch();
						}
					},
				},
			});
			return {
				submit,
				...result,
			};
		},
		useUpdateAvatar() {
			const setLoading = useLoading();
			return {
				isLoading: useAtomValue(isUploadLoading),
				async updateAvatar(file: File) {
					try {
						setLoading(true);
						const data = await upload(file);
						return {
							fileAddr: data.file || '',
							fileId: data.id || '',
						};
					} catch (error) {
						setLoading(false);
						return {
							fileAddr: '',
							fileId: '',
						};
					}
				},
			};
		},
	};
})();
