import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
	return {
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow: theme.customShadows.z16,
					borderRadius: theme.shape.borderRadiusMd,
					position: 'relative',
					zIndex: 0 // Fix Safari overflow: hidden with border radius
				}
			}
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: { variant: 'h6' },
				subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) }
			},
			styleOverrides: {
				root: {
					padding: theme.spacing(2, 3, 2)
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: theme.spacing(3)
				}
			}
		}
	};
}
