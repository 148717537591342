import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

const AXIOS_INSTANCE = Axios.create({
	baseURL: `${import.meta.env.VITE_API_BASE_URL}/api/v1`,
});

AXIOS_INSTANCE.interceptors.request.use(
	function (config) {
		const token = localStorage.getItem('accessToken');
		if (token) {
			config.headers!.Authorization = `Token ${token}`;
		}
		config.headers!['X-Http-Request-Version'] = '1.0';
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
AXIOS_INSTANCE.interceptors.response.use(
	(response) => {
		return Promise.resolve(response);
	},
	(error) => {
		console.log(error);
		return Promise.reject(error.response || 'Something went wrong');
	}
);
export interface CancelablePromise<T>
	extends Promise<{
		data: T;
		code: string | number;
		message: string;
	}> {
	cancel(): void;
}
// add a second `options` argument here if you want to pass extra options to each generated query
export const axiosInstance = <T>(
	config: AxiosRequestConfig,
	options?: AxiosRequestConfig
): CancelablePromise<T> => {
	const source = Axios.CancelToken.source();
	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise as CancelablePromise<T>;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export default AXIOS_INSTANCE;
