import { pick } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
	useMallShopConnectToStripeCreate,
	useMallShopGetInfoRead,
	useMallShopUpdateAgreementCreate,
	useMallShopUpdateInfoCreate,
	useMallShopUpdatePolicyCreate,
} from '@/api/shop/shop';
import {
	ShopInfo,
	ShopUpdatePolicyInput,
	PostMallShopUpdateInfoIdBody,
	ShopUpdateInfoInput,
	ShopShippingI18NRegion,
	ShopInfoShippingI18n,
} from '@/api/model';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import * as yup from 'yup';

import { useRouteMatch } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import {
	freeShippingMinRequiredAmountYup,
	infoYup,
	shippingFeeAmountYup,
} from '@/pages/setting/model/store';
import { addressYup } from '@/pages/setting/model/address';
import { openWindow } from '@/utils/open-window';

export enum StepValue {
	SellerAgreement,
	StoreInformation,
	StorePolicy,
	Payout,
}

export enum Status {
	ACTIVE = 'active',
	PENDING = 'pending',
}

export const RouteDirect = {
	Onboarding: '/onboarding-process',
	[StepValue.SellerAgreement]: '/onboarding-process/agreement',
	[StepValue.StoreInformation]: '/onboarding-process/information',
	[StepValue.StorePolicy]: '/onboarding-process/policy',
	[StepValue.Payout]: '/onboarding-process/payout',
	Success: '/onboarding-process/success',
};

const shopInfoAtom = atom<ShopInfo | null>(null);

export const useShopInfoValue = () =>
	useAtomValue<ShopInfo | null>(shopInfoAtom);

export const useShopInfo = () => {
	const [, setShopInfo] = useAtom(shopInfoAtom);
	return setShopInfo;
};

const step = atom<StepValue>(StepValue.SellerAgreement);
export const useStepValue = () => useAtomValue<StepValue>(step);

export const useStep = () => {
	const [, setStep] = useAtom(step);
	return setStep;
};

export type ShopForm = Pick<
	ShopInfo,
	'name' | 'email' | 'description' | 'address'
> & { avatar_id: string };
export const useShopForm = (defaultValue?: ShopForm | null) => {
	const {
		reset,
		register,
		handleSubmit,
		setValue,
		clearErrors,
		getValues,
		formState: { errors },
	} = useForm<ShopForm>({
		mode: 'all',
		defaultValues: {
			...pick(defaultValue, [
				'name',
				'email',
				'description',
				'address',
				'avatar_id',
			]),
		},
		resolver: yupResolver(
			yup.object().shape({
				...infoYup,
				address: yup.object().shape(addressYup),
			})
		),
	});
	useEffect(() => {
		if (defaultValue) {
			setValue('avatar_id', defaultValue?.avatar_id as string);
			setValue('name', defaultValue?.name);
			setValue('email', defaultValue?.email);
			setValue('description', defaultValue?.description);
			setValue('address.country', defaultValue?.address.country || 'IE');
			setValue('address.name', defaultValue?.address.name);
			setValue('address.line1', defaultValue?.address.line1);
			setValue('address.line2', defaultValue?.address.line2);
			setValue('address.city', defaultValue?.address.city);
			setValue('address.state', defaultValue?.address.state);
			setValue('address.county', defaultValue?.address.county);
			setValue('address.zipcode', defaultValue?.address.zipcode);
		}
	}, [defaultValue]);
	return {
		reset,
		register,
		handleSubmit,
		setValue,
		getValues,
		clearErrors,
		formState: { errors },
	};
};

type PolicyForm = Pick<
	ShopInfo,
	| 'shipping_fee_amount'
	| 'free_shipping_min_required_amount'
	| 'policy'
	| 'offer_free_shipping'
>;
export const usePolicyForm = (defaultValue?: ShopInfo | null) => {
	const {
		reset,
		register,
		clearErrors,
		getValues,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<{
		policy: ShopInfo['policy'];
		shipping_i18n: ShopInfo['shipping_i18n'];
	}>({
		mode: 'all',
		defaultValues: {
			...defaultValue,
		},
		resolver: yupResolver(
			yup.object().shape({
				policy: yup.string(),
			})
		),
	});

	return {
		reset,
		register,
		handleSubmit,
		clearErrors,
		setValue,
		getValues,
		formState: { errors },
	};
};

export const OnboardingModel = (function () {
	return {
		useUpdateAgreement() {
			const navigate = useNavigate();
			const { mutateAsync: submit, ...result } =
				useMallShopUpdateAgreementCreate({
					mutation: {
						onSuccess() {
							navigate(RouteDirect[StepValue.StoreInformation]);
						},
					},
				});
			return {
				submit,
				...result,
			};
		},
		useUpdateShopInfo() {
			const { user } = useAuth();
			const { refetch } = this.useGetShopInfo(user?.shop_id);
			const navigate = useNavigate();
			const { mutateAsync, ...result } = useMallShopUpdateInfoCreate({
				mutation: {
					onSuccess() {
						refetch();
						navigate(RouteDirect[StepValue.StorePolicy]);
					},
				},
			});
			return {
				async submit(shopId: string, formData: ShopUpdateInfoInput) {
					const { data } = await mutateAsync({
						shopId,
						data: formData,
					});

					return data;
				},
				...result,
			};
		},
		useUpdatePolicy() {
			const { user } = useAuth();
			const navigate = useNavigate();
			const { refetch } = this.useGetShopInfo(user?.shop_id);
			const { mutateAsync, ...result } = useMallShopUpdatePolicyCreate({
				mutation: {
					onSuccess() {
						refetch();
						navigate(RouteDirect[StepValue.Payout]);
					},
				},
			});
			return {
				async submit(shopId: string, formData: ShopUpdatePolicyInput) {
					const { data } = await mutateAsync({
						shopId,
						data: formData,
					});

					return data;
				},
				...result,
			};
		},
		useConnectStripe() {
			const { mutateAsync: submit, ...result } =
				useMallShopConnectToStripeCreate({
					mutation: {
						onSuccess({ data }) {
							openWindow(data.url);
						},
					},
				});
			return {
				submit,
				...result,
			};
		},
		useGetShopInfo(id: string) {
			const routes = [
				RouteDirect.Onboarding,
				RouteDirect[StepValue.Payout],
				RouteDirect[StepValue.StorePolicy],
				RouteDirect[StepValue.StoreInformation],
				RouteDirect[StepValue.SellerAgreement],
			];
			const routeMatch = useRouteMatch(routes);
			const navigate = useNavigate();
			const setShopInfo = useShopInfo();
			const { pathname } = useLocation();
			const { isSuccess, refetch, isFetching } = useMallShopGetInfoRead(
				id,
				{
					query: {
						onSuccess(res) {
							const shopInfo = res.data;
							setShopInfo(shopInfo);

							if (
								routeMatch &&
								shopInfo.status === Status.ACTIVE
							) {
								return setTimeout(() =>
									navigate(RouteDirect.Success)
								);
							}

							if (shopInfo.status === Status.PENDING) {
								// setp1
								if (!shopInfo.is_agree_agreement) {
									return navigate(
										RouteDirect[StepValue.SellerAgreement]
									);
								}
								// setp2
								if (
									!shopInfo.name ||
									!shopInfo.avatar_id ||
									!shopInfo.email ||
									(!shopInfo.address.county &&
										!shopInfo.address.state) ||
									!shopInfo.address.city ||
									!shopInfo.address.line1 ||
									!shopInfo.address.name ||
									!shopInfo.address.zipcode ||
									pathname ===
										RouteDirect[StepValue.StoreInformation]
								) {
									return navigate(
										RouteDirect[StepValue.StoreInformation]
									);
								}
								// setp3
								if (
									pathname ===
									RouteDirect[StepValue.StorePolicy]
								) {
									return;
								}
								// setp4
								if (
									!shopInfo.connect_stripe_account ||
									pathname === RouteDirect[StepValue.Payout]
								) {
									return navigate(
										RouteDirect[StepValue.Payout]
									);
								}
								return navigate(
									RouteDirect[StepValue.SellerAgreement]
								);
							}
						},
						enabled: false,
						staleTime: 30,
					},
				}
			);

			return {
				isSuccess,
				isFetching,
				refetch,
				shopInfo: useAtomValue(shopInfoAtom),
			};
		},
	};
})();
