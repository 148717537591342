// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

export const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	general: {
		product: {
			list: '/product',
			add: '/product/add',
			edit: '/product/edit',
		},
		order: '/order',
		iframeShows: '/iframe-shows',
		setting: '/setting',
		stripe: '/setting/stripe',
		store: '/setting/store',
		permission: '/setting/permission',
	},
};
