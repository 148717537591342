import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import './index.css';

// material
import {
	Alert,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
} from '@mui/material';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
//
import { MIconButton } from '../../@material-extend';
import invariant from 'tiny-invariant';
import { useLogin, useLoginForm } from './model';
import { WonderForm } from '@/components/form/form';
import { Button } from '@/components/@wonder/button';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router';

export default function LoginForm() {
	const { login, updateProfile } = useAuth();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [showPassword, setShowPassword] = useState(false);

	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useLoginForm();

	const { onLogin, isLoading, isError, error } = useLogin();
	const getErrorInfo = (error: any) => error?.data.message;
	const onSubmit = handleSubmit(async (values) => {
		const { data } = await onLogin({
			data: values,
		});
		let token = data?.token;
		invariant(token, 'token unfound');
		localStorage.setItem('accessToken', token!);
		login(token);
		updateProfile(data);
		enqueueSnackbar('Login success', {
			variant: 'success',
			action: (key) => (
				<MIconButton size="small" onClick={() => closeSnackbar(key)}>
					<Icon icon={closeFill} />
				</MIconButton>
			),
		});
	});

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};

	return (
		<WonderForm onSubmit={onSubmit}>
			<Stack spacing={3}>
				{isError && (
					<Alert severity="error">{getErrorInfo(error)}</Alert>
				)}

				<TextField
					fullWidth
					autoComplete="username"
					type="email"
					label="Email address"
					{...register('username')}
					error={!!errors.username}
					helperText={errors.username?.message}
					InputLabelProps={{ shrink: true }}
				/>

				<TextField
					fullWidth
					autoComplete="current-password"
					type={showPassword ? 'text' : 'password'}
					label="Password"
					{...register('password')}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleShowPassword}
									edge="end"
								>
									<Icon
										icon={
											showPassword ? eyeFill : eyeOffFill
										}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
					error={!!errors.password}
					helperText={errors.password?.message}
				/>
			</Stack>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="end"
				sx={{ my: 2 }}
			>
				<Button
					color="inherit"
					wonderSize="big"
					disableRipple
					onClick={() => {
						navigate(`/auth/password/reset`);
					}}
				>
					Forgot Password?
				</Button>
			</Stack>

			<Button
				fullWidth
				wonderSize="big"
				type="submit"
				variant="contained"
				loading={isLoading}
			>
				Login
			</Button>
		</WonderForm>
	);
}
