import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoginInput } from '@/api/model';
import { useSellerLoginCreate } from '@/api/seller/seller';

export const useLoginForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginInput>({
		mode: 'all',
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(
			yup.object().shape({
				password: yup.string().required('Password is required'),
			})
		),
	});

	return {
		register,
		handleSubmit,
		formState: { errors },
	};
};

export const useLogin = () => {
	const { mutateAsync: onLogin, ...results } = useSellerLoginCreate();
	return {
		onLogin,
		...results,
	};
};
