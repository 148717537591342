import styled from '@emotion/styled';
import React from 'react';
import { Arrow } from '@/components/arrow';
import { useNavigate } from 'react-router';
const Container = styled.div`
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 40px;
	.arrow {
		margin-right: 6px;
	}
`;

export const GoBackLogin: React.FC = () => {
	const navi = useNavigate();
	return (
		<Container
			className="grey-text flex-center-center text-center pointer"
			onClick={() => navi(`/auth/login`)}
		>
			<Arrow
				className="arrow"
				direction="left"
				color="var(--grey-color)"
			></Arrow>
			Back to Log In
		</Container>
	);
};
